import React from 'react';
import Colour from '../../utilities/contrast-check';

function TestWidget(props) {

    let colClass = Colour.whichTxt(props.config.colour, "#2a2a2a", "#ffffff");

    return (
        <div className={props.widgetTrigger ? "sample-widget visible " + colClass : "sample-widget " + colClass}>
            <div className="widget-header" style={{ backgroundColor: props.config.colour }}>
                <div className="close-icon" onClick={(e) => props.closeWidget(e)}></div>
                <h5>Chat with us</h5>
            </div>
            <div className={"widget-body"}>
                <div className="msg agpt-msg">
                    <p>{ typeof props.intro != "undefined" && props.intro !== "" ? props.intro : "Hi there! Thanks for visiting. How can I help you today?"}</p>
                </div>

                {props.msgs.map((msg, i) =>
                    <div className={msg.type === "customer" ? "msg customer-msg" : "msg agpt-msg"} key={i} style={msg.type === "customer" ? { backgroundColor: props.config.colour } : {}}>
                        <p>{msg.msg}</p>
                    </div>
                )}

                {props.widgetError &&
                    <div className={"widget-error " + props.widgetError.style}>
                        <p>{props.widgetError.msg}</p>
                    </div>
                }
            </div>
            <div className='widget-sender'>
                <textarea placeholder="Enter your message..." value={props.msg} onKeyUp={(e) => props.handlePossibleSubmit(e)} onChange={(e) => props.setMsg(e.target.value)}></textarea>
                <div className="send-btn" onClick={(e) => props.submitMsg(e)}>
                    <div className="top" style={{ backgroundColor: props.config.colour }}></div>
                    <div className="btm" style={{ backgroundColor: props.config.colour }}></div>
                    Send
                </div>
            </div>
        </div>
    )
}

export default TestWidget;
