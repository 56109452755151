import React from 'react';
import { Routes, Route } from 'react-router-dom'

// View components
import DetailsContainer from '../../../containers/users/setup/step-details-container';
import ConfigContainer from '../../../containers/users/setup/step-config-container';
import PasswordContainer from '../../../containers/users/setup/step-password-container';
import LakesContainer from '../../../containers/users/setup/step-lakes-container';
import SetupHeader from '../../common/setup-header';
import WhiteLabel from '../../../utilities/whitelabel';

const Register = (props) => {

    return (
        <div className={ WhiteLabel.changeClass("registration") }>

            { props.step > 2 ? (
                <SetupHeader title="Get started" subTitle={"Thanks for signing up"} />
            ) : (
                <SetupHeader title = "Sign up" subTitle = { false } />
            )}

            <div className={ props.step > 2 ? "reg-content big" : "reg-content"}>
                <div className={ props.displayState === "" ? "reg-steps" : "reg-steps " + props.displayState}>
                    <Routes>
                        <Route path='details-restart' element={<DetailsContainer {...props} />} />
                        <Route path='details' element={<DetailsContainer {...props} />} />
                        <Route path='password' element={<PasswordContainer {...props} />} />
                        <Route path='config' element={<ConfigContainer {...props} />} />
                        <Route path='lakes' element={<LakesContainer {...props} />} />
                        <Route index element={<DetailsContainer {...props} />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default Register;
