import React from 'react';
import { Routes, Route  } from 'react-router-dom'

import LogoutContainer from './../containers/users/auth/logout-container';
import ForgottenPwContainer from './../containers/users/auth/forgotten-pw-container';
import SetPwContainer from './../containers/users/auth/set-pw-container';
import VerifyContainer from '../containers/users/auth/verify-container';
import WhiteLabel from '../utilities/whitelabel';

function Routing(props) {

        return (
            <div className={WhiteLabel.changeClass("registration")}>

                <Routes>
                    <Route path='forgotten-password' element={ <ForgottenPwContainer/> } />
                    <Route path='set-password/:resetToken/:resetKey/:setType' element={ <SetPwContainer/> } />
                    <Route path='set-password/:resetToken/:resetKey' element={<SetPwContainer />} />
                    <Route path='verify/:token/:mash' element={ <VerifyContainer /> } />        
                    <Route path='logout' element={ <LogoutContainer/> } />
                </Routes>

            </div>
        );

}

export default Routing;
