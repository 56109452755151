// Core react and plugins
import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";

import Loader from '../common/loader';
import moment from 'moment';
import { Link } from 'react-router-dom';
import WhiteLabel from '../../utilities/whitelabel';

const ChatView = (props) => {

    if (props.loading) {
        return <Loader />;
    }

    return (
        <div>
            <Helmet>
                <title>View chat :: {WhiteLabel.changeName("ChatBack AI")}</title>
            </Helmet>

            <Link to="/chats" className="back-btn right">Back</Link>
            <h1>Chat: { props.chat.ref }</h1>

            { props.loading ? (
                <Loader />
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Message</th>
                            <th>Created</th>
                        </tr >
                    </thead >
                    <tbody>
                        { props.chat.msgs === null || props.chat.msgs.length === 0 ? (
                            <tr>
                                <td colSpan="3" className='centre padded'><em>No messages found</em></td>
                            </tr>
                        ) : (
                            <Fragment>
                                {props.chat.msgs.map((msg, index) => (
                                    <tr key={"m" + index}>
                                        <td>{msg.sender === "agent" ? WhiteLabel.changeName("ChatBack AI") : "Customer" }</td>
                                        <td>{msg.msg}</td>
                                        <td>{moment(msg.created).format("HH:mm, DD/MM/YYYY")}</td>
                                    </tr>
                                ))}
                            </Fragment>
                        )}
                    </tbody>
            </table >
            )}

            
            
        </div>
    );
}

export default ChatView;