import { Fragment } from "react";
import { GithubPicker } from 'react-color';
import { Helmet } from "react-helmet";

import WhiteLabel from "../../../utilities/whitelabel";

// View components
import Error from "../../common/forms/error";
import ValidationError from "../../common/forms/validation-error";
import LaunchBtn from "../../common/launch-btn";
import SampleWidget from "../../common/sample-widget";

const Config = (props) => {

    return (
        <Fragment>
            <Helmet>
                <title>Configure your widget :: {WhiteLabel.changeName("ChatBack AI")}</title>
            </Helmet>

            <h2>Step 1: Set up your widget</h2>
            <Error error={props.error} />

            <div className="sidebar">
                <SampleWidget col={props.details.colour ? props.details.colour : "#b80000" } intro={ props.details.introWording } />
                <p className="fig">How your Q&amp;A widget will look</p>

                <LaunchBtn col={ props.details.colour ? props.details.colour : "#b80000" } />
                <p className="fig">How your chat button will look</p>
            </div>

            <div className="main">
                <form id="configForm">

                    <div className="sxn">

                        <h3>Core configuration</h3>

                        <div className="input text">
                            <label htmlFor="url">Website URL</label>
                            <input type="text" name="url" id="url" value={props.details.url} onChange={(e) => props.handleChange("url", e.target.value)} />
                            <ValidationError fieldName="URL" errors={props.valErrors} />
                        </div>

                        <div className="input colour">
                            <label htmlFor="colour">Pick a primary colour for your website&rsquo;s chat widget</label>
                            
                            <div className="colour-input">
                                <button className="colour-swatch" style={{ backgroundColor: props.details.colour ? props.details.colour : "#b80000" }} onClick={ (e) => props.togglePicker(e) }>Select colour</button>
                                <input type="text" name="colour" id="colour" value={props.details.colour} onChange={(e) => props.handleChange("colour", e.target.value)} />

                                <div className={props.pickerOpen ? "picker-wrap open" : "picker-wrap"}>
                                    <GithubPicker
                                        onChangeComplete={props.changeColour}
                                        color={props.details.colour}
                                    />
                                </div>
                            </div>
                            <ValidationError fieldName="Colour" errors={props.valErrors} />
                        </div>

                        <div className="input select">
                            <label htmlFor="url">Where would you like the widget to appear on your website</label>
                            <select name="position" id="position" value={props.details.position} onChange={(e) => props.handleChange("position", e.target.value)}>
                                <option value="left">Bottom left</option>
                                <option value="right">Bottom right</option>
                            </select>
                            <ValidationError fieldName="Position" errors={props.valErrors} />
                        </div>

                    </div>

                    <div className="sxn">

                        <h3>Key conversation wording</h3>

                        <div className="input text">
                            <label htmlFor="introWording">Welcome message: How would you like to introduce the chat?</label>
                            <input type="text" name="introWording" id="introWording" value={props.details.introWording} onChange={(e) => props.handleChange("introWording", e.target.value)} />
                            <ValidationError fieldName="IntroWording" errors={props.valErrors} />
                        </div>

                        <div className="input text">
                            <label htmlFor="errorWording">Error message: What to say when no content is found?</label>
                            <input type="text" name="errorWording" id="errorWording" value={props.details.errorWording} onChange={(e) => props.handleChange("errorWording", e.target.value)} />
                            <ValidationError fieldName="ErrorWording" errors={props.valErrors} />
                        </div>

                    </div>

                    <button className="btn primary right" onClick={(e) => props.setConfig(e)}>Next</button>
                    <button className="btn secondary" onClick={(e) => props.skipSetup(e)}>Skip setup</button>

                </form>
            </div>
        </Fragment>
    );
}

export default Config;
