// Core react and plugins
import React from 'react';
import { Helmet } from "react-helmet";

import Error from '../../common/forms/error';
import WhiteLabel from '../../../utilities/whitelabel';

const Prompts = (props) => {

    return (
        <div>
            <Helmet>
                <title>Manage prompts :: {WhiteLabel.changeName("ChatBack AI")}</title>
            </Helmet>

            <div className="form-scrn">
                <h1>Manage prompts</h1>
                <Error error={props.error} />
            
                <form>
                    <div className="input text">
                        <label htmlFor="sysPrompt">Chat completions system intro</label>
                        <textarea name="sysPrompt" id="sysPrompt" value={props.prompts.sysPrompt} onChange={(e) => props.handleChange(e)}></textarea>
                    </div>    

                    <div className="input text">
                        <label htmlFor="chatPrompt">Chat completions message prompt</label>
                        <textarea name="chatPrompt" id="chatPrompt" value={props.prompts.chatPrompt} onChange={(e) => props.handleChange(e)}></textarea>
                    </div>

                    <div className="input text">
                        <label htmlFor="txtPrompt">Text completions (single &quot;Answer about&quot;) prompt</label>
                        <textarea name="txtPrompt" id="txtPrompt" value={props.prompts.txtPrompt} onChange={(e) => props.handleChange(e)}></textarea>
                    </div>

                    <button className="btn primary" onClick={(e) => props.handleSubmit(e)}>{ props.btnTxt }</button>
                </form>
            </div>
        </div>
    );
}

export default Prompts;