// import React from 'react';
function Footer(props){

    return "";

    // return (
    //     <footer>
    //         <div className="content">
    //             <p className='powered'>Powered by:</p>
    //             <p>&copy; 2022 ChatBack AI</p>
    //         </div>
    //     </footer>
    // )
}

export default Footer