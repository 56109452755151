import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

// View components
import Register from '../../../components/users/setup/register';

const RegisterContainer = (props) => {

    var navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [isRestart, setIsRestart] = useState(false);
    const [displayState, setDisplayState] = useState("appearing");

    // Cookies
    const [accCookie, setCookie, removeCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // get the current route using react router
    const { pathname } = useLocation();

    // Set up the options for the different steps
    const stepOpts = {
        1: "details",
        2: "password",
        3: "config",
        4: "lakes"
    }

    // Check whether we are already in the process of creating an account
    const inProgress = (cookie) => {
        if (typeof accCookie[process.env.REACT_APP_AUTH_COOKIE] === "undefined") {
            return false;
        }
        if (typeof accCookie[process.env.REACT_APP_AUTH_COOKIE].stc === "undefined") {
            return false;
        }
        if (accCookie[process.env.REACT_APP_AUTH_COOKIE].stc === 99999) {
            removeCookie(process.env.REACT_APP_AUTH_COOKIE, { path: '/', secure: true, sameSite: true });
            return false;
        }
        if (typeof accCookie[process.env.REACT_APP_AUTH_COOKIE].cr === "undefined") {
            return false;
        }
        return true;
    }

    // Load up the screen 
    const loadScreen = () => {

        // Check the point in the process based upon the path 
        // Explode the pathname around slashes
        var path = pathname.split("/");
        // Get the last non-empty item in the array 
        var last = path[path.length - 1];
        if (last === "details-restart") setIsRestart(true);
        if (last === "setup" || last === "" || last === "details-restart") last = "details";
        // Find the key for this in the stepOpts object
        var stp = parseInt(Object.keys(stepOpts).find(key => stepOpts[key] === last));

        // Check whether we are already in the process of creating an account
        if (inProgress()) {
            // Check whether the current step is the current page 
            if ((parseInt(accCookie[process.env.REACT_APP_AUTH_COOKIE].stc) + 1) !== stp) {
                // If so then navigate to the appropriate point
                navigate("/setup/" + stepOpts[accCookie[process.env.REACT_APP_AUTH_COOKIE].stc + 1]);
                return;
            }
        } else {
            // If not then check that we are on the first step
            if (last !== "details") {
                navigate("/setup/details");
                return;
            }
        }

        // Set the display state to appearing
        setDisplayState("appearing");

        // Now wait for the animation to finish
        setTimeout(() => {

            // Set the current step based upon the pathname 
            setStep(stp);
            setDisplayState("");

        }, 250);

    }

    // Handle the switch between steps
    const updateStep = (direction) => {
        // Hide the old step
        setDisplayState("hiding");

        // Wait and then update the step
        setTimeout(() => {
            // Update the step
            var newStep = (direction === "nxt") ? step + 1 : step - 1;
            setStep(newStep);

            // If the new setp exists then navigate to it
            if (stepOpts[newStep]) {
                console.log(stepOpts[newStep]);
                navigate('/setup/' + stepOpts[newStep]);
                return;
            }

            // If not then navigate to the dashboard
            navigate("/dashboard");

        }, 250);
    }

    /* eslint-disable */
    useEffect(() => {
        loadScreen();
    }, [])
    useEffect(() => {
        loadScreen();
    }, [pathname])
    /* eslint-enable */

    return (
        <Register 
            updateStep={updateStep}
            displayState={displayState}
            isRestart={isRestart}
            step={step}
            regRef={typeof accCookie[process.env.REACT_APP_AUTH_COOKIE] !== "undefined" && typeof accCookie[process.env.REACT_APP_AUTH_COOKIE].cr !== "undefined" ? accCookie[process.env.REACT_APP_AUTH_COOKIE].cr : "" }
        />
    );
}

export default RegisterContainer;
