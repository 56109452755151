import React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';

import FooterContainer from '../containers/common/footer-container';
import HeaderContainer from '../containers/common/header-container';
import Errors from './common/errors';
import AdminManageContainer from '../containers/users/admin-manage-container';
import DashboardContainer from '../containers/dashboard/dashboard-container';
import ChatsContainer from '../containers/chats/chats-container';
import WebsiteContainer from '../containers/gpt-contents/website-container';
import DataLakesContainer from '../containers/gpt-contents/data-lakes-container';
import ConfigContainer from '../containers/config/config-container';
import PackagesContainer from '../containers/billing/packages-container';
import PackageResultsContainer from '../containers/billing/package-results-container';
import UsrSettingsContainer from '../containers/users/auth/usr-settings-container';
import ChatViewContainer from '../containers/chats/chat-view-container';
import PromptsContainer from '../containers/admin/prompts/prompts-container';
import ClientsContainer from '../containers/admin/ clients/clients-container';
import WhiteLabel from '../utilities/whitelabel';

function AuthRouting() {

    const params = useParams();

    const widePages = ["config", "packages", "packages/cancel"];

    return (
            <div className={ WhiteLabel.changeClass("")}>
                <HeaderContainer />
                
                <div className={ widePages.indexOf(params["*"]) > -1 ? "main-content wide" : "main-content"}>
                    <Routes>
                        <Route exact path='/dashboard' element={<DashboardContainer />} />
                        <Route path='/chats/:chatRef' element={<ChatViewContainer />} />
                        <Route exact path='/chats' element={<ChatsContainer />} />
                        <Route exact path='/website-content' element={<WebsiteContainer />} />
                        <Route exact path='/data-lakes' element={<DataLakesContainer />} />
                        <Route exact path='/config' element={<ConfigContainer />} />
                        <Route exact path='/settings' element={<UsrSettingsContainer />} />
                        <Route exact path='/prompts' element={<PromptsContainer />} />
                        <Route exact path='/clients' element={<ClientsContainer />} />

                        <Route exact path='/packages' element={<PackagesContainer />}/>
                        <Route exact path='/packages/cancel' element={<PackagesContainer isCancel={true} />}/>
                        <Route path='/packages/:rslt' element={<PackageResultsContainer />} />
                        <Route exact path='/admin/manage/*' element={<AdminManageContainer/>}/>
                        <Route path='*' element={<Errors/>} /> 
                    </Routes>
                </div>

                <FooterContainer/>
            </div>
        );

    }

export default AuthRouting;
