import { Fragment } from "react";
import { Helmet } from "react-helmet";

// View components
import Error from "../../common/forms/error";
import ValidationError from "../../common/forms/validation-error";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

import WhiteLabel from "../../../utilities/whitelabel";

const Lakes = (props) => {

    const fiveEntries = [1, 2, 3, 4, 5];

    return (
        <Fragment>
            <Helmet>
                <title>Data lakes :: {WhiteLabel.changeName("ChatBack AI")}</title>
            </Helmet>

            <h2>Set up your data lakes</h2>
            <Error error={props.error} />

            <form id="lakesForm">
                <div className="vertical-tabs">

                    <ul className="lake-tabs">
                        { fiveEntries.map((incNo) => 
                            <li key={ "tab-" + incNo } className={ props.current === incNo ? "lake-tab active" : "lake-tab"} data-tab={"lake-" + incNo}>
                                <button className="btn btn-tab" onClick={(e) => props.updateCurrent(e, incNo)}><span>Lake </span>{ incNo }</button>
                            </li>
                        ) }
                    </ul>

                    { fiveEntries.map((incNo) =>
                        <div key={ "content-" + incNo } className={ props.current === incNo ? "sxn open" : "sxn"}>

                            <h3>Lake { incNo } configuration</h3>

                            <div className="input text">
                                <label htmlFor={"title" + incNo}>Lake title</label>
                                <input type="text" name={"title" + incNo} id={"title" + incNo} value={props.details["title" + incNo]} onChange={(e) => props.handleChange("title" + incNo, e.target.value)} />
                                <ValidationError fieldName="Title" errors={props.valErrors} />
                            </div>

                            <div className="input textarea">
                                <label htmlFor={"content" + incNo}>Lake content</label>
                                <CKEditor
                                    editor={InlineEditor}
                                    data={ typeof props.details["content" + incNo] !== "undefined" ? props.details["content" + incNo] : "Enter content..."}
                                    config={{
                                        toolbar: ['heading', '|', 'Bold', 'Italic', 'Link', "Unlink", "|", 'NumberedList', 'BulletedList', 'Blockquote', '|', 'undo', 'redo'],
                                        heading: {
                                            options: [
                                                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                                { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' }
                                            ]
                                        }
                                    }}
                                    onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        props.handleChange('content' + incNo, data, true)
                                    }}
                                />
                            </div>
                            <ValidationError fieldName="Content" errors={props.valErrors} />

                            <button className="btn primary sml" onClick={(e) => props.saveLake(e, incNo)}>Save lake</button>
                        </div>

                    )}

                </div>

                <button className="btn primary wide right" onClick={(e) => props.finishLakes(e)}>Complete setup</button>
                <button className="btn secondary mlft" onClick={(e) => props.finishLakes(e)}>Skip setup</button>

            </form>
        </Fragment>
    );
}

export default Lakes;
