// Core react and plugins
import React from 'react';
import { Helmet } from "react-helmet";

import { Link } from 'react-router-dom';

import Error from '../common/forms/error';
import WhiteLabel from '../../utilities/whitelabel';

const PackageResults = (props) => {

    return (
        <div>
            <Helmet>
                <title>Thanks for upgrading :: {WhiteLabel.changeName("ChatBack AI")}</title>
            </Helmet>

            <h1>Your {WhiteLabel.changeName("ChatBack AI")} Subscription</h1>

            <Error error={props.error} />

            <p>Thanks for updating your subscription. You can change your package anytime using the link below:</p>

            <Link to="/packages" className="btn primary mbtm">Update subscription</Link>

            <p>If you have any questions or need any assistance with your subscription, you can <a href="mailto:hello@chatback.ai">get in touch with our team</a>.</p>

        </div>
    );
}

export default PackageResults;