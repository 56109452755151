import React from 'react';
import { Helmet } from "react-helmet";

import Error from '../../common/forms/error';
import ValidationError from '../../common/forms/validation-error';
import PwMeter from './../auth/pw-meter';

import WhiteLabel from '../../../utilities/whitelabel';

const Password = (props) => {

    return (
        <form id="pwForm">
            <Helmet>
                <title>Set a password :: {WhiteLabel.changeName("ChatBack AI")}</title>
            </Helmet>

            <Error error={props.error} />

            <div className="input text password">
                <label htmlFor="password">Password</label>
                <input type="password" name="password" id="password" autoComplete="new-password" value={props.details.password} onChange={(e) => props.handleChange("password", e.target.value)} />
                <ValidationError errors={props.valErrors} fieldName="Password" />

                <PwMeter strength={props.strength} />
            </div>

            <div className="input text password">
                <label htmlFor="confirmPassword">Confirm password</label>
                <input type="password" name="confirmPassword" id="confirmPassword" autoComplete="new-password" value={props.details.confirmPassword} onChange={(e) => props.handleChange("confirmPassword", e.target.value)} />
                <ValidationError errors={props.valErrors} fieldName="ConfirmPassword" />    
            </div>

            <button className="btn primary" onClick={(e) => props.createPassword(e)}>Next</button>

        </form>
    );
}

export default Password;
