import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Error from "../../common/forms/error";
import ValidationError from "../../common/forms/validation-error";

import WhiteLabel from "../../../utilities/whitelabel";

const Details = (props) => {

    return (
        <form>
            <Helmet>
                <title>My details :: {WhiteLabel.changeName("ChatBack AI")}</title>
            </Helmet>

            <Error error={props.error} />
            { props.isRestart && 
                <Error error={{ msg: "Sorry but there was an error creating your account. Please retry below.", style: "red" }} />
            }
        
            <div className="input text">
                <label htmlFor="firstName">First name</label>
                <input type="text" name="firstName" id="firstName" value={props.details.firstName} onChange={(e) => props.handleChange("firstName", e.target.value)} />
                <ValidationError fieldName="FirstName" errors={props.valErrors} />    
            </div>

            <div className="input text">
                <label htmlFor="lastName">Last name</label>
                <input type="text" name="lastName" id="lastName" value={props.details.lastName} onChange={(e) => props.handleChange("lastName", e.target.value)} />
                <ValidationError fieldName="LastName" errors={props.valErrors} />    
            </div>

            <div className="input text email">
                <label htmlFor="email">Email address</label>
                <input type="email" name="email" id="email" value={props.details.email} onChange={(e) => props.handleChange("email", e.target.value)} />
                <ValidationError fieldName="Email" errors={props.valErrors} />    
            </div>

            <button className="btn primary" onClick={(e) => props.createUser(e)}>Next</button>

            <p className="sml centred login-lnk">Already have an account? <Link to="/">Sign in</Link></p>

        </form>
    );
}

export default Details;

/*
            <p className="sml">In signing up you are agreeing to our <a href="https://www.chatback.ai/terms" target="_blank" rel="noreferrer">Terms of Service</a>.</p>
*/