import React, { useState } from "react";

// Import utilities 
import Fetching from "./../../../utilities/fetching";
import { useCookies } from 'react-cookie';

// View components
import Details from "./../../../components/users/setup/step-details";

const DetailsContainer = (props) => {

    // Track state for this screen
    const [details, setDetails] = useState({ firstname: "", lastname: "", email: "", phone: "" });
    const [valErrors, setValErrors] = useState(false);
    const [error, setError] = useState(false);

    // Cookies
    const [accCookie, setCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // Track changes to the form state
    const handleChange = (fieldName, val) => {
        setDetails({ ...details, [fieldName]: val });
    }

    // Submit the form data via fetch 
    const createUser = ( e ) => {

        e.preventDefault();

        // Clear out any errors
        setError(false);
        setValErrors(false);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/accounts/create", {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(details)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Store a cookie with the user reference
                setCookie(process.env.REACT_APP_AUTH_COOKIE, { cr: data.detail, stc: 1 }, { path: '/', secure: true, sameSite: true });

                // Trigger the GTM / GA event
                triggerGaEvent();

                // Redirect the form to the next step
                props.updateStep("nxt");
                return;

            } 

            // Display the validation errors and error message 
            setError({ msg: data.msg, style: "red" });
            if ( data.rslt === "invalid-data" ) {
                // Process the validation errors 
                let validationErrors = Fetching.processErrors(data.detail);
                setValErrors(validationErrors);
            }

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error creating the user. Please check your data and try again", style: "red" });
        });

    }

    // Trigger the GA event to capture the sign up
    const triggerGaEvent = () => {

        if (process.env.REACT_APP_SITE_BASE === "https://app.chatback.ai") {

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'sign_up',
                'loginMethod': 'webform'
            });
            console.log("Pushed GTM sign up event");

        }

    }


    return (
        <Details 
            error={error}
            valErrors={valErrors}
            handleChange={handleChange}
            createUser={createUser}
            details={details}
            {...props}
        />
    );
}

export default DetailsContainer;
