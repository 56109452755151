// Core react and plugins
import React, {  useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '../../components/common/header';
import Encryption from './../../utilities/encryption';
// // State management
import { useCookies } from 'react-cookie';

const HeaderContainer = (props) => {
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);
    const [permissions,setPermissions] = useState({})

    const {pathname} = useLocation();
    

    const getUserPermissions = () =>{
        
        // Get the user data from the cookie 
        let authInfo = Encryption.extractHeaders(authCookie);

        setPermissions(authInfo.permissions)
    }

    /* eslint-disable */
    useEffect(() => {
        getUserPermissions()
    },[])
   /* eslint-enable */

    return (
        <Header 
            permissions={permissions}
            pathname={ pathname }
        />
    );
}

export default HeaderContainer;