// Core react and plugins
import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import Error from '../common/forms/error';
import WhiteLabel from '../../utilities/whitelabel';

const Packages = (props) => {

    let curPackage = "free";
    if (props.status.billingStatus === "active") {
        const opts = ["personal", "pro", "premium"];
        if (opts.indexOf(props.status.package) > -1) {
            curPackage = props.status.package;
        }
    }

    return (
        <div>
            <Helmet>
                <title>Upgrade :: {WhiteLabel.changeName("ChatBack AI")}</title>
            </Helmet>

            <h1>Upgrade your {WhiteLabel.changeName("ChatBack AI")} package</h1>

            { props.isCancel &&
                <Error error={{ msg: "Sorry to see you cancelled that upgrade attempt. You can change your package at any time on this page.", style: "red" }} />
            }
            <Error error={props.error} />
            
            <p>Need additional chat capacity for your website? Want to access additional {WhiteLabel.changeName("ChatBack AI")} features? You can upgrade your package below.</p>
            <p>We have given details of your current usage at the bottom of the page - to try and help you make the best decision for your organisation.</p>
            <p>If none of the packages listed give sufficient capacity, then <a href="mailto:hello@chatback.ai">get in touch</a> for custom  AI pricing.</p>

            <div className="input select currency">
                <label htmlFor="currency">Currency</label>
                <select id="currency" onChange={(e) => props.setCurrency(e.target.value)} value={props.currency}>
                    <option value="gbp">GBP (£)</option>
                    <option value="usd">USD ($)</option>
                </select>
            </div>

            <div className="packages">

                <div className={ curPackage === "personal" ? "package current" : "package"}>
                    <h3>Standard</h3>
                    {props.currency === "gbp" || props.currency === "" ? (
                        <h4>&pound;19 per month</h4>
                    ) : (
                        <h4>&#36;25 per month</h4>
                    )}

                    <ul>
                        <li>Plug &amp; play deployment</li>
                        <li>Self training AI</li>
                        <li>Reads your website content</li>
                        <li>Add your own custom content</li>
                        <li>Customisable widget</li>
                        <li>Chat history reporting</li>
                        <li>2000 customer queries per month</li>
                    </ul>

                    { curPackage !== "personal" ? (
                        <button className="btn primary" onClick={(e) => props.subscribeTo(e, "personal")}>{props.btnTxt.personal}</button>
                    ) : (
                        <button className="btn primary disabled">Current package</button>
                    )}
                </div>

                <div className={curPackage === "pro" ? "package major current" : "package major"}>
                    <h3>Professional</h3>
                    { props.currency === "gbp" || props.currency === "" ? (
                        <h4>&pound;49 per month</h4>
                    ) : (
                        <h4>&#36;65 per month</h4>
                    )}

                    <ul>
                        <li>Plug &amp; play deployment</li>
                        <li>Self training AI</li>
                        <li>Reads your website content</li>
                        <li>Add your own custom content</li>
                        <li>Customisable widget</li>
                        <li>Chat history reporting</li>
                        <li>5000 customer queries per month</li>
                    </ul>

                    { curPackage !== "pro" ? (
                        <button className="btn primary" onClick={(e) => props.subscribeTo(e, "pro")}>{props.btnTxt.pro}</button>
                    ) : (
                    <button className="btn primary disabled">Current package</button>
                    )}
                </div>

                <div className={curPackage === "premium" ? "package current" : "package"}>
                    <h3>Premium</h3>
                    {props.currency === "gbp" || props.currency === "" ? (
                        <h4>&pound;99 per month</h4>
                    ) : (
                        <h4>&#36;129 per month</h4>
                    )}

                    <ul>
                        <li>Plug &amp; play deployment</li>
                        <li>Self training AI</li>
                        <li>Reads your website content</li>
                        <li>Add your own custom content</li>
                        <li>Customisable widget</li>
                        <li>Chat history reporting</li>
                        <li>10,000 customer queries per month</li>
                    </ul>

                    { curPackage !== "premium" ? ( 
                        <button className="btn primary" onClick={(e) => props.subscribeTo(e, "premium")}>{props.btnTxt.premium}</button>
                    ) : (
                        <button className="btn primary disabled">Current package</button>
                    )}
                </div>

            </div>

            <div className="usage">
                <ul>
                    <li><span>{ props.usage ? props.usage.msgThisMonth : "-" }</span> Messages so far this month</li>
                    <li><span>{props.usage ? props.usage.projectedThisMonth : "-"}</span> Projected messages this month</li>
                    <li><span>{props.usage ? props.usage.avgMsgMonthly : "-"}</span> Ave messages per month (previous 12 months)</li>
                </ul>
            </div>

            <div className="cancel">
                <p>If you have decided to end your use of {WhiteLabel.changeName("ChatBack AI")}, you can <Link to="#cancel" onClick={ (e) => props.cancel(e) }>cancel your subscription</Link>.</p>
            </div>
        </div>
    );
}

export default Packages;