// Core react and plugins
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import Encryption from '../../utilities/encryption';
import Fetching from '../../utilities/fetching';
import TestWidget from '../../components/demo/test-widget';
import TestLaunchBtn from '../../components/demo/test-launch-btn';


const TestContainer = (props) => {

    // State management 
    const [websiteUrl, setWebsiteUrl] = useState("");
    const [config, setConfig] = useState(false);
    const [openWidget, setOpenWidget] = useState(false);
    const [chatRef , setChatRef] = useState("");
    const [msgs, setMsgs] = useState([]);
    const [msg, setMsg] = useState("");
    const [error, setError] = useState(false);
    const [widgetError, setWidgetError] = useState(false);

    const [accCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // Load up the widget configuration for this user (just the colours etc)
    const loadConfig = () => {

        setError(false);

        // Get the auth permissions based upon the cokie 
        let authInfo = Encryption.extractHeaders(accCookie);

        // Run the fetch request
        fetch(process.env.REACT_APP_API_BASE + "/test/load-config", {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.token,
                'api-key': authInfo.key
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Display the widget for them to chat with
                setConfig(data.detail);

            } 

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error loading the configuration. Please try again.", style: "red" });
        });

    }

    // Load up the stats for the header of the page 
    const startChat = (e) => {

        e.preventDefault();
        setError(false);

        // Open out the widget 
        setOpenWidget(true);
        return;

    }

    // Handle a key press that could be a submission by enter key 
    const handlePossibleSubmit = (e) => {
        // Check for the enter key
        if (e.key === "Enter") {
            submitMsg();
        }
        setWidgetError(false);
    }

    // Handle the submission of a message 
    const submitMsg = (e) => {
        if (typeof e !== "undefined") e.preventDefault();

        // Check for a customer message 
        if (msg.trim() === "") {
            setWidgetError({ msg: "Please enter a message.", style: "red" });
            return;
        }
        setWidgetError(false);

        // Add the customer message to the messages array 
        let updatedMsgs = [...msgs, { msg: msg, type: "customer" }];
        setMsgs(updatedMsgs);
        let submission = { chatRef: chatRef, msg: msg }
        setMsg("");
        scrollWidget();

        let authInfo = Encryption.extractHeaders(accCookie);

        // Do the fetch to hanbdle the selecton of a response
        // Run the fetch request
        fetch(process.env.REACT_APP_API_BASE + "/test/msg", {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.token,
                'api-key': authInfo.key
            },
            body: JSON.stringify(submission)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){

                // Update the chat widget with the new message
                var newMsgs = [...updatedMsgs];
                var cr = false;
                for (var i in data.detail) {
                    newMsgs.push(data.detail[i]);
                    cr = data.detail[i].chatRef;
                }
                setMsgs(newMsgs);
                scrollWidget();
                if (cr) setChatRef(cr);
                return;

            } 

            // Display a returned error message
            setWidgetError({ msg: data.msg, style: "red" });

        })
        .catch(function (error) {
            setWidgetError({ msg: "Sorry but there was an error processing your message. Please try again.", style: "red" });
        });

    }

    // Close the widget and forget the chat 
    const closeWidget = (e) => {
        e.preventDefault();

        // Close the widget
        setOpenWidget(false);

        // Clear out the chat ref
        setChatRef("");

        // Clear out the messages
        setMsgs([]);

        // Clear out the message
        setMsg("");

        // Clear out any errors
        setError(false);
        setWidgetError(false);
    }

    // Scroll the widget to the bottom
    const scrollWidget = () => {
        setTimeout(() => {
            const widget = document.querySelector(".widget-body");
            widget.scrollTop = widget.scrollHeight;
        }, 100);
    }

    // Use effect to load up the config on page load 
    /* eslint-disable */
    useEffect(() => {
        loadConfig();
    }, []);
    /* eslint-enable */

    // If the widget is not yet triggered, display the button
    if (!openWidget) {
        return (
            <TestLaunchBtn 
                config={ config }
                startChat={ startChat }
            />
        );
    }

    return (
        <TestWidget 
            config={ config }
            closeWidget={ closeWidget }
            websiteUrl={ websiteUrl }
            setWebsiteUrl={setWebsiteUrl}
            openWidget={ openWidget }
            msgs={ msgs }
            msg={ msg }
            setMsg={ setMsg }
            submitMsg={ submitMsg }
            handlePossibleSubmit={ handlePossibleSubmit }
            startChat={ startChat }
            widgetError={ widgetError }
            error={ error }
        />
    );
}

export default TestContainer;