// Core react and plugins
import React from 'react';
import { Helmet } from "react-helmet";

import { Link } from 'react-router-dom';
import TextInput from '../../common/forms/text-input';

// Components
import Error from './../../common/forms/error';
import SetupHeader from '../../common/setup-header';
import WhiteLabel from '../../../utilities/whitelabel';

// Component class itself
function ForgottenPw(props) {

    return (
        <div className="registration">
            <Helmet>
                <title>Forgotten password :: {WhiteLabel.changeName("ChatBack AI")}</title>
            </Helmet>

            <SetupHeader title="Forgotten password" subTitle={false} />

            <div className="login-content">

                <Error error={ props.error } />

                <form>
                    <TextInput label={'Email Address'} id={'email'} handleChange={ props.handleUpdate} />

                    <button className="btn primary wider" onClick={ (e) => props.submitFunc(e) }>{ props.btnTxt }</button>
                    <p className="sml right"><Link to="/">Return to login</Link></p>
                </form>

            </div>
        </div>
    );

}

export default ForgottenPw;