// Core react and plugins
import React, {useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import Encryption from '../../utilities/encryption';
import Fetching from '../../utilities/fetching';

import Chats from '../../components/chats/chats';

const ChatsContainer = (props) => {

    // State management 
    const [chats, setChats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    const [accCookie, setCookie, removeCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // Load up the chats for the client
    const loadChats = () => {

        if (!hasMore) return;

        // Get the auth data from the cookie
        let authInfo = Encryption.extractHeaders(accCookie);

        // Run the fetch request
        fetch(process.env.REACT_APP_API_BASE + "/chats/list/" + page, {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.token,
                'api-key': authInfo.key
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){

                if (data.detail !== null) {
                
                    // Process the lakes into the appropriate format
                    var newChats = [...chats, ...data.detail];
                    setChats(newChats);

                    if (data.detail.length < 25) {
                        setHasMore(false);
                    }
                    setPage(page + 1);

                } else {
                    setHasMore(false);
                }

            } 
            setLoading(false);

        })
        .catch(function (error) {});

    }

    // On scroll to bottom, load more chats
    window.onscroll = () => {
        // Calculate the height of the window
        const winHeight = window.innerHeight;
        const documentHeight = document.body.scrollHeight;
        const scrollPosition = window.scrollY;

        if (documentHeight - (scrollPosition + winHeight) < 100) {
            if (!loading) {
                setLoading(true);
                loadChats();
            }
        } 
    }

    /* eslint-disable */
    useEffect(() => {
        loadChats();
    }, []);
    /* eslint-enable */

    return (
        <Chats 
            chats={chats}
            loading={loading}
        />
    );
}

export default ChatsContainer;