// Core react and plugins
import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";

import { Link } from 'react-router-dom';
import WhiteLabel from '../../utilities/whitelabel';

const Dashboard = (props) => {

    let barWidth = 0;
    if (!isNaN(props.stats.totalMsgs) && !isNaN(props.stats.msgLimit) && props.stats.msgLimit > 0) {
        barWidth = (props.stats.totalMsgs / props.stats.msgLimit) * 100;
        if (props.stats.totalMsgs > props.stats.msgLimit) {
            barWidth = 100;
        }
    }

    let widgetUrl = "https://w.chatback.ai";
    let verifyBrackets = "(You should have been emailed a link when you first signed up - if you have not received it, please contact us: support @chatback.ai)";
    if (process.env.REACT_APP_PRODUCT !== "chatback") {
        widgetUrl = process.env.REACT_APP_PRODUCT_URL.replace("https://", "https://gpt-widget.");
        verifyBrackets = "(You should have been emailed a link when you first signed up)";
    }

    return (
        <div className="dashboard">
            <Helmet>
                <title>Dashboard :: {WhiteLabel.changeName("ChatBack AI")}</title>
            </Helmet>
            
            <section className="stats thirds">
                <div className='orange'>
                    <p><span>{ props.stats.totalChats }</span></p>
                    <p>Total chats (<Link to="/chats">View</Link>)</p>
                </div>
                <div className='green'>
                    <p><span>{ props.stats.totalMsgs }</span></p>
                    <p>Total messages</p>
                </div>
                <div className='grey'>
                    <p><span>{ props.stats.totalSources }</span></p>
                    <p>Training resources</p>
                </div>
            </section>

            { (process.env.REACT_APP_PRODUCT === "chatback" || process.env.REACT_APP_PRODUCT === "ChatBack") &&
                <section className="progress">
                    <div className='bar'>
                        <div className={ barWidth > 80 ? 'perc-used red' : 'perc-used'} style={{ width: barWidth + "%" }}>&nbsp;</div>
                    </div>
                    <Link to="/packages" className="btn upgrade">Upgrade<span> now</span></Link>
                    <p>{ props.stats.totalMsgs } of { props.stats.msgLimit.toLocaleString() } monthly messages used</p>
                </section>
            }

            <section className="installer">
                <h3>Install the widget on your website</h3>
                <p>To install {WhiteLabel.changeName("ChatBack AI")} on your website simply copy and paste the code snippent below into your site&rsquo;s HTML just before the closing &lt;body&gt; tag.</p>
                <div className="code">
                    { props.isVerified ? (
                        <Fragment>
                            &lt;!-- Start {WhiteLabel.changeName("ChatBack AI")} widget --&gt;<br />
                            &lt;script&gt;<br />
                                window.onload = function()&#123;<br />
                                !function(e) &#123;<br />
                                    document.getElementsByTagName("body")[0].insertAdjacentHTML("beforeend", '&lt;div id="agptRt"&gt;&lt;/div&gt;');<br />
                                    var t = document.createElement("script");
                                    t.type = "text/javascript", t.async = !0, t.src = "{widgetUrl}/w/agpt.js?k={ props.clientRef }", t.id = "agptScr", t.onload = () => &#123;<br />
                                        document.getElementsByTagName("head")[0].insertAdjacentHTML("beforeend", '&lt;link rel="stylesheet" type="text/css" href="{widgetUrl}/w/agpt.css" /&gt;')<br />
                                    &#125;;<br />
                                    var a = document.getElementsByTagName("script")[0]; a.parentNode.insertBefore(t, a)<br />
                                &#125;(window, void 0);<br />
                                &#125;<br />
                            &lt;/script&gt;<br />
                            &lt;!-- End {WhiteLabel.changeName("ChatBack AI")} snippet --&gt;
                        </Fragment>
                    ) : (
                        <p className="block-cover">
                            <span>Please verify your email address before you can install the widget.</span><br />
                            { verifyBrackets }
                        </p>
                    )}
                </div>

            </section>
        </div>
    );
}

export default Dashboard;

/*
                <Link to="/#wix" className="btn primary wix">Wix widget</Link>
                <Link to="/#wordpress" className="btn primary wordpress">Wordpress widget</Link>
                */