// Core react and plugins
import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";

import Error from '../common/forms/error';
import ValidationError from '../common/forms/validation-error';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import Loader from '../common/loader';
import WhiteLabel from '../../utilities/whitelabel';
import TestContainer from '../../containers/demo/test-container';

const DataLakes = (props) => {

    let dlName = "data lakes";
    let dlTitle = "Data lake";
    let lname = "Lake";
    let err = props.error;
    if (process.env.REACT_APP_PRODUCT === "Ami") {
        dlName = "documents";
        dlTitle = "Document";
        lname = "Document";
        if (typeof err.msg !== "undefined" && err.msg !== "") {
            err.msg = err.msg.replace("lake", "document");
        }
    }

    return (
        <div>
            <Helmet>
                <title>Manage { dlName } :: {WhiteLabel.changeName("ChatBack AI")}</title>
            </Helmet>

            <h1>Manage { dlName }</h1>

            <Error error={ err } />

            <form id="lakesForm">
                <div className="vertical-tabs">

                    { !props.details ? (
                        <Loader />
                    ) : (
                        <Fragment>
                            <ul className="lake-tabs">
                                { props.details.map((lake, incNo) =>
                                    <li key={"tab-" + incNo} className={props.current === (incNo + 1) ? "lake-tab active" : "lake-tab"} data-tab={"lake-" + incNo}>
                                        <button className="btn btn-tab" onClick={(e) => props.handleTabChange(e, incNo)}>{lake.title !== "" ? (incNo + 1) : ""}<span>{lake.title === "" ? dlTitle + " " : ": " + lake.title}</span>{lake.title !== "" ? "" : (incNo + 1)}</button>
                                    </li>
                                )}
                            </ul>

                            { props.details.map((lake, incNo) =>
                                <div key={"content-" + incNo} className={props.current === (incNo + 1) ? "sxn open" : "sxn"}>

                                    <h3>{lname} {incNo + 1} configuration</h3>

                                    <div className="input text">
                                        <label htmlFor={"title" + incNo}>{lname} title</label>
                                        <input type="text" name={"title" + incNo} id={"title" + incNo} value={ lake.title } onChange={(e) => props.handleChange("title", incNo, e.target.value)} />
                                        <ValidationError fieldName="Title" errors={props.valErrors} />
                                    </div>

                                    <div className="input textarea">
                                        <label htmlFor={"content" + incNo}>{lname} content</label>
                                        <CKEditor
                                            editor={InlineEditor}
                                            data={ typeof lake.content != "undefined" && lake.content !== "" ? lake.content : ""}
                                            config={{
                                                toolbar: ['heading', '|', 'Bold', 'Italic', 'Link', "Unlink", "|", 'NumberedList', 'BulletedList', 'Blockquote', '|', 'undo', 'redo'],
                                                heading: {
                                                    options: [
                                                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                                        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' }
                                                    ]
                                                }
                                            }}
                                            onBlur={(event, editor) => {
                                                const data = editor.getData();
                                                props.handleChange('content', incNo, data)
                                            }}
                                        />
                                    </div>
                                    <ValidationError fieldName="Content" errors={props.valErrors} />

                                    <button className="btn primary sml" onClick={(e) => props.saveLake(e)}>{ (lname === "Lake") ? props.btnTxt : props.btnTxt.replace("lake", lname) }</button>
                                </div>

                            )}
                        </Fragment>
                    )}

                </div>

            </form>

            { parseInt(process.env.REACT_APP_INTERNAL_TEST) === 1 &&
                <div className="int-widget">
                    <TestContainer />
                </div>
            }
        </div>
    );
}

export default DataLakes;