// Core react and plugins
import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

// Utilities 
import Fetching from '../../utilities/fetching';
import Encryption from '../../utilities/encryption';
import Colour from '../../utilities/contrast-check';

import Config from '../../components/config/config';

const ConfigContainer = (props) => {

    const navigate = useNavigate(); // eslint-disable-line

    // Track state for this screen
    const [details, setDetails] = useState({ url: "", colour: "#b80000", position: "left", introWording: "", errorWording: "" });
    const [pickerOpen, setPickerOpen] = useState(false);
    const [txtClass, setTxtClass] = useState("light"); // eslint-disable-line
    const [valErrors, setValErrors] = useState(false);
    const [error, setError] = useState(false);
    const [btnTxt, setBtnTxt] = useState("Save");

    // Cookie / auth
    const [accCookie, setCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // Track changes to the form state
    const handleChange = (fieldName, val) => {
        setDetails({ ...details, [fieldName]: val });
        if (fieldName === "colour" && val.length >= 6) {
            var txtCol = Colour.whichTxt(val, "#2a2a2a", "#ffffff");
            setTxtClass(txtCol);
        }
    }

    const changeColour = (col) => {
        setDetails({ ...details, colour: col.hex });
        setPickerOpen(false);
        if (col.hex.length >= 6) {
            var txtCol = Colour.whichTxt(col.hex, "#2a2a2a", "#ffffff");
            setTxtClass(txtCol);
        }
    }

    const togglePicker = (e) => {
        e.preventDefault();
        setPickerOpen(!pickerOpen);
    }

    // Load up the existing config for the current user 
    const getConfig = () => {

        // Get the auth data from the cookie
        let authInfo = Encryption.extractHeaders(accCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/config", {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.token,
                'api-key': authInfo.key
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Store a cookie with the user reference
                if (data.detail.position === "") data.detail.position = 'left';
                if (data.detail.colour === "") data.detail.colour = '#b80000';
                setDetails(data.detail);
                return;

            } 

            // Display the validation errors and error message 
            setError({ msg: data.msg, style: "red" });

        })
        .catch(function (error) {
            console.log(error);
            setError({ msg: "Sorry but there was an error retrieving the config data for your widget. Please refresh the page to try again", style: "red" });
        });

    }

    // Save the config data
    const saveConfig = ( e ) => {

        setBtnTxt("Saving...");

        e.preventDefault();

        // Clear out any errors
        setError(false);
        setValErrors(false);

        // Get the auth data from the cookie
        let authInfo = Encryption.extractHeaders(accCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/accounts/config", {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.token,
                'api-key': authInfo.key
            },
            body: JSON.stringify(details)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setBtnTxt("Save");
            scrollToTop();

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Redirect the form to the next step
                setError({ msg: data.msg, style: "green" });
                return;

            } 

            // Display the validation errors and error message 
            setError({ msg: data.msg, style: "red" });
            // Process validation errors
            if (data.rslt === "invalid-data") {
                let valErrs = Fetching.processErrors(data.detail);
                setValErrors(valErrs);
            }

        })
        .catch(function (error) {
            scrollToTop();
            setBtnTxt("Save");
            setError({ msg: "Sorry but there was an error saving your data. Please check your entry and try again", style: "red" });
        });

    }

    // Function to trigger the page scrolling to the top
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    // Use effect to call the getConfig function on load
    /* eslint-disable */
    React.useEffect(() => {
        getConfig();
    }, []);
    /* eslint-enable */

    return (
        <Config 
            details={details}
            pickerOpen={pickerOpen}
            valErrors={valErrors}
            error={error}
            handleChange={handleChange}
            changeColour={changeColour}
            togglePicker={togglePicker}
            saveConfig={saveConfig}
            btnTxt={btnTxt}
            txtClass={txtClass}
        />
    );
}

export default ConfigContainer;