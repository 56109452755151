import React, { useState } from "react";
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

// Utilities 
import Fetching from "../../../utilities/fetching";
import CryptoJS from 'crypto-js';

// View components
import Password from "../../../components/users/setup/step-password";

const PasswordContainer = (props) => {

    const navigate = useNavigate();

    // Track state for this screen
    const [details, setDetails] = useState({ password: "", confirmPassword: "" });
    const [valErrors, setValErrors] = useState(false);
    const [error, setError] = useState(false);
    const [strength, setStrength] = useState({
        score: 0,
        lc: false,
        uc: false,
        length: false,
        number: false,
        char: false,
        noDict: false,
        hasEntry: false
    });

    // Cookie / auth
    let encSecret = process.env.REACT_APP_ENC_SECRET;
    const [accCookie, setCookie, removeCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // Track changes to the form state
    const handleChange = (fieldName, val) => {
        setDetails({ ...details, [fieldName]: val });
        if (fieldName === "password") updateStrength(val);
    }

    // Check the strength of the password
    const updateStrength = (pw) => {
        // Set up the object
        var newStrength = { ...strength };
        newStrength.score = 0;

        // Check that we have an entry
        newStrength.hasEntry = (pw.length > 0) ? true : false;

        // Is the password long enough
        newStrength.length = (pw.length > 7) ? true : false;
        if (newStrength.length) newStrength.score++;

        // Check for letters and numbers
        var i;
        var character;
        newStrength.number = false;
        newStrength.lc = false;
        newStrength.uc = false;
        for (i = 0; i < pw.length; i++) {

            character = pw.charAt(i);

            // Check for numbers
            if (character !== ' ' && !isNaN(character * 1)) {
                newStrength.number = true;
            } else {

                if (character === character.toUpperCase() && (/^[a-zA-Z0-9]*$/.test(character) === true)) {
                    // Check for uppercase letters
                    newStrength.uc = true;
                } else if (character === character.toLowerCase() && (/^[a-zA-Z0-9]*$/.test(character) === true)) {
                    // Check for lowercase letters
                    newStrength.lc = true;
                }

            }

        }
        if (newStrength.lc) newStrength.score++;
        if (newStrength.uc) newStrength.score++;
        if (newStrength.number) newStrength.score++;

        // Check for special characters
        var pw_test = pw.replace(/ /g, '');
        newStrength.char = false;
        if (/^[a-zA-Z0-9]*$/.test(pw_test) === false && pw.length > 0) {
            newStrength.char = true;
            newStrength.score++;
        }

        // Finally a dictionary check
        // var commonPws = JSON.parse('<?php echo $forbiddenWords; ?>');
        var commonPws = ["password"]
        newStrength.noDict = true;
        var lowerPw = pw.toLowerCase();
        for (var j in commonPws) {
            if (lowerPw.indexOf(commonPws[j]) > -1) {
                newStrength.noDict = false;
                break;
            }
        }
        if (newStrength.noDict) newStrength.score++;

        setStrength(newStrength);

    }

    // Submit the form data via fetch 
    const createPassword = ( e ) => {

        e.preventDefault();

        // Clear out any errors
        setError(false);
        setValErrors(false);

        // Set up the body 
        var reqBody = {...details};
        reqBody.ref = props.regRef;

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/accounts/password", {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(reqBody)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Store a cookie with the user reference
                var encData = CryptoJS.AES.encrypt(JSON.stringify(data.detail), encSecret).toString();
                setCookie(process.env.REACT_APP_AUTH_COOKIE, { cr: props.regRef, stc: 2, a: encData }, { path: '/', secure: true, sameSite: true });

                // Redirect the form to the next step
                props.updateStep("nxt");
                return;

            } 

            // If there is a missing client or user then delete the cookie and redirect to the start
            if(data.rslt === 'no-client' || data.rslt === 'no-user' ){
                removeCookie(process.env.REACT_APP_AUTH_COOKIE, { path: '/', secure: true, sameSite: true });
                navigate("/setup/details-restart");
                return;
            }

            // Display the validation errors and error message 
            setError({ msg: data.msg, style: "red" });
            // Process validation errors
            if (data.rslt === "invalid-data") {
                let valErrs = Fetching.processErrors(data.detail);
                setValErrors(valErrs);
            }

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error setting your password. Please check your entry and try again", style: "red" });
        });

    }

    return (
        <Password
            error={error}
            valErrors={valErrors}
            handleChange={handleChange}
            createPassword={createPassword}
            details={details}
            strength={strength}
            {...props}
        />
    );
}

export default PasswordContainer;
