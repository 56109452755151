import React, { useState } from "react";
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

// Utilities 
import Fetching from "../../../utilities/fetching";
import CryptoJS from 'crypto-js';
import useAuth from './../../../store/auth';

// View components
import Config from "../../../components/users/setup/step-config";
import Encryption from "../../../utilities/encryption";

const ConfigContainer = (props) => {

    const navigate = useNavigate();
    let encSecret = process.env.REACT_APP_ENC_SECRET;

    // Track state for this screen
    const [details, setDetails] = useState({ url: "", colour: "#b80000", position: "left", introWording: "", errorWording: "" });
    const [pickerOpen, setPickerOpen] = useState(false);
    const [valErrors, setValErrors] = useState(false);
    const [authState, authActions] = useAuth(); // eslint-disable-line
    const [error, setError] = useState(false);

    // Cookie / auth
    // let encSecret = process.env.REACT_APP_ENC_SECRET;
    const [accCookie, setCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // Track changes to the form state
    const handleChange = (fieldName, val) => {
        setDetails({ ...details, [fieldName]: val });
    }

    const changeColour = (col) => {
        setDetails({ ...details, colour: col.hex });
        setPickerOpen(false);
    }

    const togglePicker = (e) => {
        e.preventDefault();
        setPickerOpen(!pickerOpen);
    }

    // Submit the form data via fetch 
    const setConfig = ( e ) => {

        e.preventDefault();

        // Clear out any errors
        setError(false);
        setValErrors(false);

        // Get the auth data from the cookie
        let authInfo = Encryption.extractHeaders(accCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/accounts/config", {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.token,
                'api-key': authInfo.key
            },
            body: JSON.stringify(details)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Store a cookie with the user reference
                setCookie(process.env.REACT_APP_AUTH_COOKIE, { cr: props.regRef, stc: 3, a: accCookie[process.env.REACT_APP_AUTH_COOKIE].a }, { path: '/', secure: true, sameSite: true });

                // Redirect the form to the next step
                props.updateStep("nxt");
                return;

            } 

            // Display the validation errors and error message 
            setError({ msg: data.msg, style: "red" });
            // Process validation errors
            if (data.rslt === "invalid-data") {
                let valErrs = Fetching.processErrors(data.detail);
                setValErrors(valErrs);
            }

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error setting your password. Please check your entry and try again", style: "red" });
        });

    }

    // Handle the skipping to the dashboard 
    const skipSetup = (e) => {
        e.preventDefault();

        // Check whether there is unsaved data on the form
        let unsaved = false;
        for (var i in details) {
            if (details[i] !== "") {
                unsaved = true;
                break;
            }
        }

        // If there is no unsaved then smply skip
        if (!unsaved) {
            setCookie(process.env.REACT_APP_AUTH_COOKIE, { cr: "", stc: 99999, a: accCookie[process.env.REACT_APP_AUTH_COOKIE].a }, { path: '/', secure: true, sameSite: true });
            doLoginToDashboard();
            return;
        }

        // Display a confirm message 
        if (window.confirm("You have not saved all your configuration settings. If you continue you will lose any unsaved data (and will need to recreate it on the admin pages). Are you sure you want to continue?")) {
            setCookie(process.env.REACT_APP_AUTH_COOKIE, { cr: "", stc: 99999, a: accCookie[process.env.REACT_APP_AUTH_COOKIE].a }, { path: '/', secure: true, sameSite: true });
            doLoginToDashboard();
        }

    }

    // Do a login to the dashboard 
    const doLoginToDashboard = () => {

        // Get the auth data from the cookie
        let authInfo = Encryption.extractHeaders(accCookie);

        // Submit the login request
        fetch(process.env.REACT_APP_API_BASE + '/users/direct-login', {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.token,
                'api-key': authInfo.key
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success' && typeof data.token != "undefined" && typeof data.key != "undefined") {

                const dataToStore = {
                    apiKey:data.key,
                    apiToken:data.token,
                    key: data.key,
                    token: data.token,
                    client:data.usrDetail.Client,
                    email:data.usrDetail.Email,
                    firstName:data.usrDetail.FirstName,
                    lastName:data.usrDetail.LastName,
                    manager:data.usrDetail.Manager,
                    mobileNo:data.usrDetail.MobileNo,
                    permissions:data.usrDetail.Permissions,
                    ref:data.usrDetail.Ref
                }

                // Upon successful login, store the session info to state (and into an encrypted cookie for more permanent storage)
                authActions.storeSession(dataToStore);

                var encData = { a: CryptoJS.AES.encrypt(JSON.stringify(dataToStore), encSecret).toString() };
                setCookie(process.env.REACT_APP_AUTH_COOKIE, encData, { path: '/', secure: true, sameSite: true });
                navigate('/dashboard')
                return;

            } 
            navigate("/");            

        })
        .catch(function (error) {
            navigate("/");            
        });

    }

    return (
        <Config
            details={details}
            error={error}
            valErrors={valErrors}
            handleChange={handleChange}
            pickerOpen={pickerOpen}
            togglePicker={togglePicker}
            changeColour={changeColour}
            setConfig={setConfig}
            skipSetup={skipSetup}
            {...props}
        />
    );
}

export default ConfigContainer;
