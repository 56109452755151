// Core react and plugins
import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import Password from '../../common/forms/password';
import TextInput from '../../common/forms/text-input';

// Components
import SetupHeader from './../../common/setup-header';
import Error from './../../../components/common/forms/error';
import Button from '../../common/forms/button';
import WhiteLabel from '../../../utilities/whitelabel';

// Component class itself
function Login(props) {

    return (
        <div className={ WhiteLabel.changeClass("registration")}>
            <Helmet>
                <title>Sign in :: {WhiteLabel.changeName("ChatBack AI")}</title>
            </Helmet>
            
            <SetupHeader title="Sign in" subTitle={false} />

            <div className="login-content">

                <Error error={ props.error } />

                <form>

                    <TextInput label='Email' id='email' handleChange={(e) => props.handleAuthInfo('email',e) } />
                    <Password label='Password' id='password' handleChange={(e) => props.handleAuthInfo('password',e)} />

                    <Button handleClick={ (e) => props.loginFunc(e) } btnTxt={ props.btnTxt }/>
                    <p className="sml right">Forgotten your password? <Link to="/u/forgotten-password">Reset it here</Link></p>
                </form>
            
                { (process.env.REACT_APP_PRODUCT === "ChatBack" || process.env.REACT_APP_PRODUCT === "chatback") &&
                    <section className="new-usr">
                        <h3>New to {WhiteLabel.changeName("ChatBack AI")}?</h3>
                        <p>Create a free account to see how we can help you serve your customers.</p>
                        <Link to="/setup" className="btn primary wider">Create account</Link>
                    </section>
                }
            </div>

        </div>
    );

}

export default Login;