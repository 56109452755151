// Core react and plugins
import React, {Fragment} from 'react';

import DemoWidget from './demo-widget';
import ArrowImg from './../../assets/img/chatback/arrow.webp';
import WhiteLabel from '../../utilities/whitelabel';

const Demo = (props) => {

    return (
        <section className={ WhiteLabel.changeClass("agpt-demo")}>
            <div className="agpt-content">
            
                <div className="agpt-demo-form half">
                    { process.env.REACT_APP_PRODUCT === "chatback" &&
                        <Fragment>
                            <h3>Try it out</h3>
                            <p>Check out how {WhiteLabel.changeName("ChatBack AI")} can interact with your website now. Simply enter the URL of a page on your website into the field below, wait for the widget to load (on the right) and then start asking questions about the content on the page.</p>
                        </Fragment>
                    }
                
                    <form>
                        <div className="input text required">
                            <label htmlFor="url">Website URL</label>
                            <input type="text" name="url" id="url" required="required" onChange={ (e) => props.setWebsiteUrl(e.target.value) } />
                            { props.error && 
                                <div className="val-msg">
                                    <p>{props.error.msg}</p>
                                </div>
                            }    
                        </div>

                        <button className="agpt-submit-btn btn primary" onClick={ (e) => props.startChat(e) }>Start demo</button>
                    </form>    
                </div>

                <div className="agpt-demo-widget half">
            
                    <div className="phone-demo"></div>
                    <div className="phone-demo-glow"></div>
                    <div className="phone-demo-placeholder">
                        <div className="ph-header">
                            <h4>See how {WhiteLabel.changeName("ChatBack AI")} will look on your website</h4>
                            <p>(Requires iframes to be enabled)</p>
                            <img src={ ArrowImg } alt="Arrow to the left" />
                        </div>                       
                    </div>
                    { props.displayWidget &&
                        <Fragment>
                            <iframe src={props.websiteUrl} title={WhiteLabel.changeName("ChatBack AI") + " Demo"} className="phone-bg"></iframe>

                            <DemoWidget 
                                col={ (process.env.REACT_APP_PRODUCT === "chatback") ? "#db3e00" : "#e32088"}
                                intro="Hi there! Thanks for visiting. How can I help you today?"
                                { ...props }
                            />
                        </Fragment>
                    }
                </div>

            </div>
        </section>
    );
}

export default Demo;