// Check the status of a fetch request
const WhiteLabel = {
    changeClass: function (className) {

        if (process.env.REACT_APP_PRODUCT !== "chatback") {
            if (process.env.REACT_APP_PRODUCT === "Ami" || process.env.REACT_APP_PRODUCT === "ami") {
                className += " meetami";
            } else {
                className += " " + (process.env.REACT_APP_PRODUCT.toLowerCase());
            }
        }

        return className;

    },
    changeName: function (name) {

        if (process.env.REACT_APP_PRODUCT !== "chatback") {
            name = process.env.REACT_APP_PRODUCT;
        }

        return name;

    },
    changeLaunch: function (wording) {

        if (process.env.REACT_APP_PRODUCT === "Ami" || process.env.REACT_APP_PRODUCT === "ami" || process.env.REACT_APP_PRODUCT === "MeetAmi") {
            wording = "Chat to me";
        }

        return wording;

    },
}

export default WhiteLabel;
