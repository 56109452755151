// Core react and plugins
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

import Encryption from '../../utilities/encryption';
import Fetching from '../../utilities/fetching';

import Dashboard from '../../components/dashboard/dashboard';

const DashboardContainer = (props) => {

    // State management 
    const [stats, setStats] = useState({ totalChats: "-", totalMsgs: "-", totalSources: "-", msgLimit: "-" });
    const [clientRef , setClientRef] = useState(false);
    const [isVerified, setIsVerified] = useState(false);

    const [accCookie, setCookie, removeCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // Load up the stats for the header of the page 
    const loadStats = () => {

        // Get the auth data from the cookie
        let authInfo = Encryption.extractHeaders(accCookie);
        setClientRef(authInfo.client.Ref);

        // Run the fetch request
        fetch(process.env.REACT_APP_API_BASE + "/chats/stats", {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.token,
                'api-key': authInfo.key
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Process the lakes into the appropriate format
                setStats(data.detail);
                setIsVerified(data.detail.isVerified);

            } 

        })
        .catch(function (error) {});

    }

    // Load up the stats on page load using effect
    /* eslint-disable */
    React.useEffect(() => {
        loadStats();
    }, []);
    /* eslint-enable */

    return (
        <Dashboard 
            stats={stats}
            clientRef={clientRef}
            isVerified={isVerified}
        />
    );
}

export default DashboardContainer;