// TODO: Handling CSRF attacks?!?!
// Submit an MFA token to complete login

// Core react and plugins
import React, { useState, useEffect } from 'react';

// Utilities
import Fetching from '../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../store/flash';

// View components
import Create from '../../components/users/create';
import { useParams } from 'react-router-dom';
const CreateContainer = (props) => {

    const urlPath = useParams();

    let isUserEdit = true;
    let initialBtnText = "Edit User"
    if(typeof urlPath != "undefined" && urlPath.userRef === 'create'){
        isUserEdit = false;
        initialBtnText = "Create User"
    }

    // State management
    let encSecret = process.env.REACT_APP_ENC_SECRET;
    const [loading, setLoading] = useState(false); // eslint-disable-line
    const [rdr, setRdr] = useState(false);
    const [error, setError] = useState(false);
    // const [title, setTitle] = useState("Create account");
    const [btnTxt, setBtnTxt] = useState(initialBtnText);
    const [settings, setSettings] = useState({ firstName: "", lastName: "", email: "" });
    const [permissions, setPermissions] = useState({ Reports: false, Users: false, Questionnaire: false, Clients: false });
    const [returnEmailLink,setReturnEmailLink] = useState(false)
    const [clients,setClients] = useState([]);
    const [valErrors, setValErrors] = useState(false);
    const [flashState, flashActions] = useFlash(); // eslint-disable-line
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);
    // const [managerId,setManagerId] = useState(-1)
    const [clientId,setClientId] = useState(-1)
    const [userManager,setUserManager] = useState({})

    const [emailLink,setEmailLink] = useState(false)
    const [lightBoxOpen,toggleLightBoxOpen] = useState(false)


    let loginUrl = "/";
    const redirectUrl = "/intro"

    // Handle the field updates 
    const handleUpdate = (fieldname, e) => {
        var newSettings = { ...settings };
        newSettings[fieldname] = e.target.value;
        setSettings(newSettings);
    }

    const handlePermissionsUpdate = (fieldname, e) => {
        var newSettings = { ...permissions };
        newSettings[fieldname] = e.target.checked;
        setPermissions(newSettings);
    }

    let loadClients = (pageNo) => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_AUTH_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_AUTH_COOKIE] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            setRdr("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_AUTH_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/clients', {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setLoading(false)
            setClients(data)
            
        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
            }
            setRdr(loginUrl);
        });
    }

   

    // Submit the details for saving
    const saveUser = ( e ) => {

        e.preventDefault();
        setError(false);
        setValErrors({})

        var nbtn = (isUserEdit) ? "Editing..." : "Creating...";
        setBtnTxt(nbtn);

        let dataToPost = {
            clientId:Number(clientId),
            managerId:userManager.ID,
            firstName:settings.firstName,
            lastName:settings.lastName,
            email:settings.email,
            phone:settings.phone,
            returnLink:returnEmailLink,
            permissions:{
                reports:permissions.Reports,
                users:permissions.Users,
                questionnaire:permissions.Questionnaire,
                clients:permissions.Clients
            }
        }

        console.log(dataToPost)


        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_AUTH_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_AUTH_COOKIE] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            setRdr("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_AUTH_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // var email = encodeURI(settings.email_address.replace("+", "*PLUS*"));

        var url = (isUserEdit) ? '/users/edit/'+urlPath.userRef  : '/users/create';

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
            body: JSON.stringify(dataToPost)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if(data.rslt === 'success' ){
                if( data.detail != null){
                    setEmailLink(data.detail)
                    toggleLightBoxOpen(true)
                } else {
                    let message= "A new user has been created.  They have been emailed a password create link"
                    
                    if(isUserEdit){
                        message = 'The user has been updated'
                    } 

                    setError({ msg: message, style: "grn" });
                    flashActions.set({ msg: message , style: "grn" });
                    setRdr('/admin/manage/users')
                }
                
            } else if (data.rslt === 'invalid-client-manager'){
                setValErrors(
                    {
                        'manager':['Please select ensure a manager and client are selected'],
                        'client':['Please select ensure a manager and client are selected']
                   }
                );

            } else if (data.rslt === 'usr-valid-error' || data.rslt === "invalid-data"){
                setError({ msg: "Sorry, please ensure the user details are filled out correctly", style: "red" });
                
            }else if (data.rslt === 'save-error'){
                setError({ msg: "Sorry, there was an error saving on the server", style: "red" });
                
            } else if (data.rslt === 'usr-exists'){
                setError({ msg: "Sorry, there was an error", style: "red" });
                setValErrors(
                    {
                        'email':['This email address already exists in an account'],
                   }
                );
            }

            var nbtn = (isUserEdit) ? "Edit" : "Create";

            setBtnTxt(nbtn);


        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error creating the user. Please check your data and try again", style: "red" });
        });

    }

    // Load up existing user details
    const loadUsr = () => {
        // Check whether there is an edit param set up
        if (typeof urlPath.userRef == "undefined") {
            return;
        }

        // return;

        setLoading(true);

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_AUTH_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_AUTH_COOKIE] === "undefined") {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            setRdr("/");
            return;
        }

        // Get the user details from the server
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_AUTH_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8)); 
        
        fetch(process.env.REACT_APP_API_BASE + '/users/'+ urlPath.userRef , {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            let newPermissions = data.Permissions
            setPermissions(newPermissions)

            let newSettings = {
                phone:data.MobileNo,
                email:data.Email,
                firstName:data.FirstName,
                lastName:data.LastName
            }
            setSettings(newSettings)

            setClientId(data.Client.ID)

            setUserManager(data.Manager)

            setLoading(false);

        })
        .catch(function (error) {
            flashActions.set({ msg: "Sorry but we could not find the user you are looking for. PLease try again.", style: "red" });
            setRdr('/users');
        });        
    }

    const checkUserPermissions = () =>{
        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_AUTH_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_AUTH_COOKIE] === "undefined" ) {
            // Not logged in so everything is cool
            setRdr("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_AUTH_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));
        
        if(!reformData.permissions.Clients){
            setRdr(redirectUrl)
            return
        } else {
            loadClients();
            if(isUserEdit){
                loadUsr();
            }
        }
    }

    /* eslint-disable */
    useEffect(() => {
        checkUserPermissions()
        document.title = 'Create user :: NeuroSphere';
    },[])
    /* eslint-enable */

    const getClientOptions = (clients) => {
        let clientOptions = []
        for(let i = 0 ; i< clients.length ; i++){
            clientOptions.push(
                {
                    value:clients[i].id,
                    label:clients[i].clientName
                }
            )
        }

        return clientOptions;
    } 

    const clientOptions = getClientOptions(clients)

    const handleClientChange = (e) => {
        setClientId(e.target.value)
    }



    console.log(settings)
    return (
        <Create
            error={ error }
            valErrors={ valErrors }
            rdr={ rdr }
            // loading={ loading }
            // settings={ settings }
            handleUpdate={ handleUpdate }
            handlePermissionsUpdate={handlePermissionsUpdate}
            clientOptions={clientOptions}
            // setManagerId={setManagerId}
            saveUser={saveUser}
            setReturnEmailLink={setReturnEmailLink}
            handleClientChange={handleClientChange}
            clientId={clientId}
            permissions={permissions}
            settings={settings}
            userManager={userManager}
            setUserManager={setUserManager}
            // saveUser={ saveUser }
            // groups={ groups }
            // title={ title }
            isUserEdit={isUserEdit}
            btnTxt={ btnTxt }
            emailLink={emailLink}
            lightBoxOpen={lightBoxOpen}
            toggleLightBoxOpen={toggleLightBoxOpen}
            // timezones={ timezones }
        />
    );
}

export default CreateContainer;
