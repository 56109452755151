import React from 'react';
import WhiteLabel from '../../utilities/whitelabel';

function LaunchBtn(props){

    return (
        <div className={ WhiteLabel.changeClass("launch-btn " + props.txtClass)} style={{ backgroundColor: props.col }}>
            <p>{ WhiteLabel.changeLaunch("Ask a question")}</p>
        </div>
    )
}

export default LaunchBtn