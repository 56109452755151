// Core react and plugins
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

import DataLakes from '../../components/gpt-contents/data-lakes';
import Encryption from '../../utilities/encryption';
import Fetching from '../../utilities/fetching';

const DataLakesContainer = (props) => {

    // Track state for this screen
    const [current, setCurrent] = useState(1);
    const [btnTxt, setbtnTxt] = useState("Save lake");
    const [details, setDetails] = useState(false);
    const [valErrors, setValErrors] = useState(false);
    const [error, setError] = useState(false);

    const lakeLimit = 5;

    const [accCookie, setCookie, removeCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // Update the tab that is displayed
    const handleTabChange = (e, tabKey) => {
        e.preventDefault();
        if (!details[current - 1].isUpdated || tabKey === (current - 1)) {
            setCurrent(tabKey + 1);
            setValErrors(false);
            setError(false);
        } else if (window.confirm("Are you sure you want to change tabs? You may lose any unsaved changes if you do no save your changes ot this tab first.")) {
            setCurrent(tabKey + 1);
            handleChange("isUpdated", current - 1, false);
            setValErrors(false);
            setError(false);
        }
    }

    // Handle the update of a field
    const handleChange = (fieldName, key, value) => {
        let newDetails = [...details];
        newDetails[key][fieldName] = value;
        if (fieldName !== "isUpdated") newDetails[key].isUpdated = true;
        setDetails(newDetails);
    }

    // Load the form content 
    // Load up existing lakes if this is a page refresh 
    const loadLakes = () => {

        // Get the auth data from the cookie
        let authInfo = Encryption.extractHeaders(accCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/accounts/lakes", {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.token,
                'api-key': authInfo.key
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Process the lakes into the appropriate format
                var newLakes = [];
                for (var i in data.detail) {
                    newLakes.push({ id: 0, title: "", content: "", isUpdated: false })
                    newLakes[i].id = data.detail[i].id;
                    newLakes[i].title = data.detail[i].title;
                    newLakes[i].content = data.detail[i].content;
                }

                // Top this up to the limit 
                if (newLakes.length < lakeLimit) {
                    for (var j = newLakes.length; j < lakeLimit; j++) {
                        newLakes.push({ id: 0, title: "", content: "", isUpdated: false });
                    }
                }

                setDetails(newLakes);
            } 

        })
        .catch(function (error) {});

    }

    // Save the content for a speicifc lake
    const saveLake = ( e ) => {

        if (e) e.preventDefault();

        // Clear out any errors
        setError(false);
        setbtnTxt("Saving...");
        setValErrors(false);

        // Set up the save data
        let saveData = {
            id: details[current - 1]["id"],
            title: details[current - 1]["title"],
            content: details[current - 1]["content"],
            sortOrder: current
        }

        // Get the auth data from the cookie
        let authInfo = Encryption.extractHeaders(accCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/accounts/lake", {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.token,
                'api-key': authInfo.key
            },
            body: JSON.stringify(saveData)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setbtnTxt("Save lake");
            scrollToTop();
            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Set a success message
                setError({ msg: data.msg, style: "green" });

                //Track the ID that you just saved 
                let newDetails = [...details];
                newDetails[current - 1]["id"] = data.detail;
                newDetails[current - 1]["isUpdated"] = false;
                setDetails(newDetails);
                return;

            } 

            // Display the validation errors and error message 
            setError({ msg: data.msg, style: "red" });
            // Process validation errors
            if (data.rslt === "invalid-data") {
                let valErrs = Fetching.processErrors(data.detail);
                setValErrors(valErrs);
            }

        })
        .catch(function (error) {
            setbtnTxt("Save lake");
            setError({ msg: "Sorry but there was an error setting your password. Please check your entry and try again", style: "red" });
        });

    }

    // Scroll to top of page
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    // Load up the lakes on page load 
    /* eslint-disable */
    React.useEffect(() => {
        loadLakes();
    }, []);
    /* eslint-enable */

    return (
        <DataLakes 
            details={details}
            current={current}
            valErrors={valErrors}
            error={error}
            handleTabChange={handleTabChange}
            handleChange={handleChange}
            saveLake={saveLake}
            btnTxt={btnTxt}
        />
    );
}

export default DataLakesContainer;