import React, { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

// Utilities 
import Fetching from "../../../utilities/fetching";
import useAuth from './../../../store/auth';

// View components
import Encryption from "../../../utilities/encryption";
import Lakes from "../../../components/users/setup/step-lakes";
import CryptoJS from 'crypto-js';

const LakesContainer = (props) => {

    const navigate = useNavigate();

    let encSecret = process.env.REACT_APP_ENC_SECRET;
   
    // Track state for this screen
    const [current, setCurrent] = useState(1);
    const [details, setDetails] = useState({ id1: 0, id2: 0, id3: 0, id4: 0, id5: 0, title1: "", content1: "",  title2: "", content2: "", title3: "", content3: "", title4: "", content4: "", title5: "", content5: "" });
    const [valErrors, setValErrors] = useState(false);
    const [authState, authActions] = useAuth(); // eslint-disable-line
    const [error, setError] = useState(false);

    // Cookie / auth
    // let encSecret = process.env.REACT_APP_ENC_SECRET;
    const [accCookie, setCookie, removeCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // Track changes to the form state
    const handleChange = (fieldName, val) => {
        setDetails({ ...details, [fieldName]: val });
    }

    // Load up existing lakes if this is a page refresh 
    const loadLakes = () => {

        // Get the auth data from the cookie
        let authInfo = Encryption.extractHeaders(accCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/accounts/lakes", {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.token,
                'api-key': authInfo.key
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Process the lakes into the appropraite format
                var newLakes = {...details};
                for (var i in data.detail) {
                    newLakes["id" + data.detail[i].sortOrder] = data.detail[i].id;
                    newLakes["title" + data.detail[i].sortOrder] = data.detail[i].title;
                    newLakes["content" + data.detail[i].sortOrder] = data.detail[i].content;
                }
                setDetails(newLakes);

            } 

        })
        .catch(function (error) {});

    }


    // Submit the form data via fetch 
    const saveLake = ( e, lakeNo ) => {

        if (e) e.preventDefault();

        // Clear out any errors
        setError(false);
        setValErrors(false);

        // Set up the save data
        let saveData = {
            id: details["id" + lakeNo],
            title: details["title" + lakeNo],
            content: details["content" + lakeNo],
            sortOrder: lakeNo
        }

        // Get the auth data from the cookie
        let authInfo = Encryption.extractHeaders(accCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/accounts/lake", {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.token,
                'api-key': authInfo.key
            },
            body: JSON.stringify(saveData)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Set a success message
                setError({ msg: data.msg, style: "green" });

                //Track the ID that you just saved 
                let newDetails = {...details};
                newDetails["id" + lakeNo] = data.detail;
                setDetails(newDetails);
                return;

            } 

            // Display the validation errors and error message 
            setError({ msg: data.msg, style: "red" });
            // Process validation errors
            if (data.rslt === "invalid-data") {
                let valErrs = Fetching.processErrors(data.detail);
                setValErrors(valErrs);
            }

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error setting your password. Please check your entry and try again", style: "red" });
        });

    }

    // Press of the final next button
    // Do a final save of all the lakes and then move on to the dashboard - the setup process is over
    const finishLakes = ( e ) => {

        e.preventDefault();

        // Clear out any errors
        setError(false);
        setValErrors(false);

        // Set up the save data
        let doingSave = false;
        for (var i = 1; i <= 5; i++) {
            if (details["title" + i] !== "" || details["content" + i] !== "") {
                doingSave = true;
            }
        }

        // If we are not saving then move on to the dashboard
        if (!doingSave) {
            setCookie(process.env.REACT_APP_AUTH_COOKIE, { cr: "", stc: 99999, a: accCookie[process.env.REACT_APP_AUTH_COOKIE].a }, { path: '/', secure: true, sameSite: true });
            doLoginToDashboard();
            return;
        }

        // Display a confirm message 
        if (window.confirm("You have not saved all your lakes. If you continue you will lose any unsaved data. Are you sure you want to continue?")) {
            setCookie(process.env.REACT_APP_AUTH_COOKIE, { cr: "", stc: 99999, a: accCookie[process.env.REACT_APP_AUTH_COOKIE].a }, { path: '/', secure: true, sameSite: true });
            doLoginToDashboard();
        }
    }

    // Do a login to the dashboard 
    const doLoginToDashboard = () => {

        // Get the auth data from the cookie
        let authInfo = Encryption.extractHeaders(accCookie);

        // Submit the login request
        fetch(process.env.REACT_APP_API_BASE + '/users/direct-login', {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.token,
                'api-key': authInfo.key
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success' && typeof data.token != "undefined" && typeof data.key != "undefined") {

                const dataToStore = {
                    apiKey:data.key,
                    apiToken:data.token,
                    key: data.key,
                    token: data.token,
                    client:data.usrDetail.Client,
                    email:data.usrDetail.Email,
                    firstName:data.usrDetail.FirstName,
                    lastName:data.usrDetail.LastName,
                    manager:data.usrDetail.Manager,
                    mobileNo:data.usrDetail.MobileNo,
                    permissions:data.usrDetail.Permissions,
                    ref:data.usrDetail.Ref
                }

                // Upon successful login, store the session info to state (and into an encrypted cookie for more permanent storage)
                authActions.storeSession(dataToStore);

                var encData = { a: CryptoJS.AES.encrypt(JSON.stringify(dataToStore), encSecret).toString() };
                setCookie(process.env.REACT_APP_AUTH_COOKIE, encData, { path: '/', secure: true, sameSite: true });
                navigate('/dashboard')
                return;

            } 
            navigate("/");            

        })
        .catch(function (error) {
            navigate("/");            
        });

    }

    // Update the current lake
    const updateCurrent = (e, id) => {
        e.preventDefault();
        setCurrent(id);
        setError(false);
        setValErrors(false);
        saveLake(false, id);
    }

    // On component load load up existing lakes 
    /* eslint-disable */
    useEffect(() => {
        loadLakes();
    }, []);
    /* eslint-enable */

    return (
        <Lakes
            current={current}
            updateCurrent={updateCurrent}
            details={details}
            error={error}
            valErrors={valErrors}
            handleChange={handleChange}
            saveLake={saveLake}
            finishLakes={finishLakes}
            {...props}
        />
    );
}

export default LakesContainer;
