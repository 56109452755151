// Core react and plugins
import React from 'react';
import { Helmet } from "react-helmet";

import Error from '../common/forms/error';
import SampleWidget from '../common/sample-widget';
import LaunchBtn from '../common/launch-btn';
import ValidationError from '../common/forms/validation-error';
import { GithubPicker } from 'react-color';
import WhiteLabel from '../../utilities/whitelabel';

const Config = (props) => {

    return (
        <div>
            <Helmet>
                <title>Widget Configuration :: {WhiteLabel.changeName("ChatBack AI")}</title>
            </Helmet>

            <h1>Edit widget configuration</h1>

            <Error error={props.error} />

            <div className="sidebar">
                <SampleWidget col={props.details.colour ? props.details.colour : "#b80000"} txtClass={ props.txtClass } intro={props.details.introWording} />
                <p className="fig">How your Q&amp;A widget will look</p>

                <LaunchBtn col={props.details.colour ? props.details.colour : "#b80000"} txtClass={props.txtClass} />
                <p className="fig">How your chat button will look</p>
            </div>

            <div className="main by-side">
                <form id="configForm">

                    <div className="sxn">

                        <h3>Core configuration</h3>

                        <div className="input text">
                            <label htmlFor="url">Website URL</label>
                            <input type="text" name="url" id="url" value={props.details.url} onChange={(e) => props.handleChange("url", e.target.value)} />
                            <ValidationError fieldName="URL" errors={props.valErrors} />
                        </div>

                        <div className="input colour">
                            <label htmlFor="colour">
                                Choose a primary color for your website&rsquo;s chat widget 
                                <span>You can also add your own custom colour hex reference below</span>
                            </label>

                            <div className="colour-input">
                                <button className="colour-swatch" style={{ backgroundColor: props.details.colour ? props.details.colour : "#b80000" }} onClick={(e) => props.togglePicker(e)}>Select colour</button>
                                <input type="text" name="colour" id="colour" value={props.details.colour} onChange={(e) => props.handleChange("colour", e.target.value)} />

                                <div className={props.pickerOpen ? "picker-wrap open" : "picker-wrap"}>
                                    <GithubPicker
                                        onChangeComplete={props.changeColour}
                                        color={props.details.colour}
                                    />
                                </div>
                            </div>
                            <ValidationError fieldName="Colour" errors={props.valErrors} />
                        </div>

                        <div className="input select">
                            <label htmlFor="url">Where would you like the widget to appear on your website</label>
                            <select name="position" id="position" value={props.details.position} onChange={(e) => props.handleChange("position", e.target.value)}>
                                <option value="left">Bottom left</option>
                                <option value="right">Bottom right</option>
                            </select>
                            <ValidationError fieldName="Position" errors={props.valErrors} />
                        </div>

                    </div>

                    <div className="sxn">

                        <h3>Key conversation wording</h3>

                        <div className="input text">
                            <label htmlFor="introWording">Welcome message: How would you like to introduce the chat?</label>
                            <input type="text" name="introWording" id="introWording" value={props.details.introWording} onChange={(e) => props.handleChange("introWording", e.target.value)} />
                            <ValidationError fieldName="IntroWording" errors={props.valErrors} />
                        </div>

                        <div className="input text">
                            <label htmlFor="errorWording">Error message: What to say when no content is found?</label>
                            <input type="text" name="errorWording" id="errorWording" value={props.details.errorWording} onChange={(e) => props.handleChange("errorWording", e.target.value)} />
                            <ValidationError fieldName="ErrorWording" errors={props.valErrors} />
                        </div>

                    </div>

                    <button className="btn primary" onClick={(e) => props.saveConfig(e)}>{ props.btnTxt }</button>

                </form>
            </div>
        </div>
    );
}

export default Config;