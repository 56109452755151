import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Permissions from '../../utilities/permissions';

// Assets
import Logo from './../../assets/img/chatback/logo-white.webp';
// import AmiLogo from './../../assets/img/ami/ami-logo.svg'

function Header(props){

    const [menuOpen, setMenuOpen] = useState(false);

    const contentPages = ["/website-content", "/data-lakes"];

    const toggleMenu = (e) => {
        e.preventDefault();
        setMenuOpen(!menuOpen);
    }

    let dlName = "Data lakes";
    if (process.env.REACT_APP_PRODUCT === "MeetAmi" || process.env.REACT_APP_PRODUCT === "meetami" || process.env.REACT_APP_PRODUCT === "Ami") {
        dlName = "Documents";
    }


    return (
        <header className="sidebar">
            <div className='logo'>
                { (process.env.REACT_APP_PRODUCT === "chatback" || process.env.REACT_APP_PRODUCT === "ChatBack") &&
                    <img src={Logo} alt="ChatBack AI logo" />
                }
                { /*(process.env.REACT_APP_PRODUCT === "MeetAmi" || process.env.REACT_APP_PRODUCT === "meetami" || process.env.REACT_APP_PRODUCT === "Ami") &&
                    <img src={AmiLogo} alt="MeetAmi logo" />
            */}
            </div>

            <button className={ menuOpen ? "menu-toggle open" : "menu-toggle"} onClick={ (e) => toggleMenu(e) }>Menu</button>

            <div className={ menuOpen ? 'menu open' : 'menu'}>
                <nav>
                    <ul>
                        <li><Link to='/dashboard' className={ props.pathname === "/dashboard" ? "current" : "" }>Dashboard</Link></li>
                        <li><Link to='/chats' className={ props.pathname === "/chats" ? "current chats" : "chats"}>Chat history</Link></li>
                        <li>
                            <Link to='/website-content' className={ contentPages.indexOf(props.pathname) > -1 ? "gpt-content current" : "gpt-content"}>Training content</Link>
                            <ul>
                                <li><Link to='/website-content' className={props.pathname === "/website-content" ? "current" : ""}>Website content</Link></li>
                                <li><Link to='/data-lakes' className={props.pathname === "/data-lakes" ? "current" : ""}>{ dlName }</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to='/config' className={ props.pathname === "/config" ? "config current" : "config"}>Configuration</Link>
                        </li>
                        { Permissions.actionCheck('prompts', 'manage', props.permissions) &&
                            <li>
                                <Link to='/prompts' className={props.pathname === "/prompts" ? "config current" : "config"}>Admin</Link>
                                <ul>
                                    <li><Link to='/prompts' className={props.pathname === "/prompts" ? "config current" : "config"}>Prompts</Link></li>
                                    <li><Link to='/clients' className={props.pathname === "/clients" ? "current" : ""}>Clients</Link></li>
                                </ul>
                            </li>
                        }
                    </ul>
                </nav>
            </div>

            <div className={ menuOpen ? 'usr-menu open' : 'usr-menu'}>
                <nav>
                    <ul>
                        <li><Link to='/settings' className={ props.pathname === "/settings" ? "settings current" : "settings"}>Account settings</Link></li>
                        <li><Link to='/u/logout' className="logout">Logout</Link></li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default Header