import React, { Fragment } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import RoutingContainer from './containers/routing-container';
import AuthRoutingContainer from './containers/auth-routing-container';
import Errors from './components/common/errors';
import RegisterContainer from './containers/users/setup/register-container';
import LoginContainer from './containers/users/auth/login-container';
import DemoContainer from './containers/demo/demo-container';

function App() {

  return (
    <Fragment>

      <BrowserRouter>
        <Routes>

          <Route path='/setup/*' element={<RegisterContainer />} />

          <Route path='/demo/WIxkfQOSHd0r6HEnpV9HPCE/agpt' element={<DemoContainer />} />
          <Route path='/demo/WIxkfQOSHd0r6HEnpV9HPCE/cbai' element={<DemoContainer />} />
          <Route path='/u/*' element={<RoutingContainer />} />
          <Route exact path='/' element={<LoginContainer />} />
          <Route path='/*' element={<AuthRoutingContainer />} />
          <Route element={<Errors />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
