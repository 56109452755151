import React, {Fragment} from 'react';

import WhiteLabel from '../../utilities/whitelabel';

function SetupHeader(props){

    return (
        <header className="setup">
            
            <h1>{ props.title }</h1>
            { props.subTitle &&
                <Fragment>
                    <h2>{props.subTitle}</h2>
                    <p>{WhiteLabel.changeName("ChatBack AI")} makes it simple to setup chat for your website. In the next two steps we will walk you through the process of setting up {WhiteLabel.changeName("ChatBack AI")}&rsquo;s chat functionality on your website.</p>
                </Fragment>
            }
        </header>
    )
}

export default SetupHeader