// Check the status of a fetch request
const Colour = {
    whichTxt: function (hexRef, darkCol, lightCol) {

        // Convert the hex ref to RGB 
        let rgb = this.hexToRgb(hexRef);
        let rgbDark = this.hexToRgb(darkCol);
        let rgbLight = this.hexToRgb(lightCol);

        // Calculate the contrast ratio
        let lum = this.luminance(rgb.r, rgb.g, rgb.b);
        let lumDark = this.luminance(rgbDark.r, rgbDark.g, rgbDark.b);
        let lumLight = this.luminance(rgbLight.r, rgbLight.g, rgbLight.b);

        // Calculate the two contrast ratios
        let contrastDark = this.contrast(lumDark, lum);
        let contrastLight = this.contrast(lum, lumLight);

        return contrastDark < contrastLight ? "dark" : "light";

    },
    hexToRgb: function (hexRef) {

        // Remove a hash from the beginning 
        hexRef = hexRef.replace("#", "");
       
        // Cenvert the hex ref to RGB components 
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hexRef = hexRef.replace(shorthandRegex, function (m, r, g, b) {
            return r + r + g + g + b + b;
        });

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexRef);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;

    }, 
    luminance: function (r, g, b) {

        // Calculate the luminance of an RGB colour
        var a = [r, g, b].map(function (v) {
            v /= 255;
            return v <= 0.03928
                ? v / 12.92
                : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;

    },
    contrast: function (lum1, lum2) {

        if (lum1 > lum2) {
            return (lum2 + 0.05) / (lum1 + 0.05);
        }
        return (lum1 + 0.05) / (lum2 + 0.05);

    }
}

export default Colour;
