import React from 'react';

function SampleWidget(props){

    return (
        <div className={"sample-widget " + props.txtClass}>
            <div className="widget-header" style={{ backgroundColor: props.col }}>
                <h5>Chat with us</h5>
            </div>
            <div className="widget-body">
                <div className="msg agpt-msg">
                    <p>{ props.intro !== "" ? props.intro : "Hi there! Thanks for visiting. How can I help you today?"}</p>
                </div>
                <div className="msg customer-msg" style={{ backgroundColor: props.col }}>
                    <p>Hello, can you help me track my recent order?</p>
                </div>
                <div className="msg agpt-msg">
                    <p>Sure you just need to visit the &quot;Where is my order?&quot; page.</p>
                </div>
            </div>
            <div className='widget-sender'>
                <textarea placeholder="Enter your message..."></textarea>
                <div className="send-btn">
                    <div className="top" style={{ backgroundColor: props.col }}></div>
                    <div className="btm" style={{ backgroundColor: props.col }}></div>
                    Send
                </div>
            </div>
        </div>
    )
}

export default SampleWidget