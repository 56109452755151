// Submit an MFA token to complete login

// Core react and plugins
import React, { useState, useEffect } from 'react';

// Utilities
import Fetching from '../../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../../store/flash';

import ManagerList from '../../../components/users/managers';
const ManagerListContainer = () => {

    // State management
    let encSecret = process.env.REACT_APP_ENC_SECRET;
    const [rdr, setRdr] = useState(false);
    const [error, setError] = useState(false);
    const [managers, setManagers] = useState([]);
    const [flashState, flashActions] = useFlash();
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);

    const [editManagerMap, setEditManagerMap] = useState({}) 
    const [savingRequest, setSavingRequest] = useState({}) 
    let loginUrl = "/";
    const redirectUrl = "/intro"

    const handleEditUpdate = (fieldname,i, e) => {

        e.preventDefault();
        var newManagers = [];

        for(let j = 0; j< managers.length; j++){
            let newManager = {...managers[j]}

            if(i === j){
                newManager[fieldname] = e.target.value;
            }
            newManagers.push(newManager)
        }

        setManagers(newManagers);
    }

    const saveManager = (i, e) => {
        e.preventDefault();

        let managerToSave = managers[i];

        e.preventDefault();
        setError(false);

        let newSavingRequest = {...savingRequest}
        newSavingRequest[i] = true
        setSavingRequest(newSavingRequest)
        // var nbtn = (!isClientEdit) ? "Creating Client..." : "Editing Client...";
        // setBtnTxt(nbtn);

       

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_AUTH_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_AUTH_COOKIE] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            setRdr("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_AUTH_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // console.log(reformData);
        // return;
        let data = {
            firstName:managerToSave.FirstName,
            lastName:managerToSave.LastName,
            clientId:reformData.client.ID
        }

        var url = '/managers/' + managerToSave.ID;
        var methodType = 'PUT';
        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: methodType ,
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
            body: JSON.stringify(data)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if(data.rslt === 'success'){

                let newEditManagerMap = {...editManagerMap}
                delete newEditManagerMap[i];
                setEditManagerMap(newEditManagerMap)

                var message =  "The manger has been updated"  ;

                setError({ msg: message, style: "grn" });
                flashActions.set({ msg: message, style: "grn" });
            } else if (data.rslt === 'invalid-name' ){
                setError({ msg: "Sorry but there was an error updating the manager. Please check your data and try again", style: "red" });
                flashActions.set({ msg: "Sorry but there was an error updating the manager. Please check your data and try again", style: "red" });
            }
            
            let newSavingRequest = {...savingRequest}
            delete newSavingRequest[i]
            setSavingRequest(newSavingRequest)

        })
        .catch(function (error) {
            let newSavingRequest = {...savingRequest}
            delete newSavingRequest[i]
            setSavingRequest(newSavingRequest)
            setError({ msg: "Sorry but there was an error updating the manager. Please check your data and try again", style: "red" });
        });

    }

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadManagers = (pageNo) => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_AUTH_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_AUTH_COOKIE] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            setRdr("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_AUTH_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));


        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/managers', {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Set the state for the users
            setManagers(data)
            // setError({ msg: "Sorry but there was an error loading the users.", style: "red" });
            
        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
            }
            setRdr(loginUrl);
        });
    }

    const toggleEdit = (e,index) => {

        e.preventDefault()
        let newEditManagerMap = {...editManagerMap}
        newEditManagerMap[index] = true;
        setEditManagerMap(newEditManagerMap)
    }

    const deleteManager = ( managerId,e ) => {

        e.preventDefault();
        setError(false);

        let doDelete = window.confirm('Do you wish to delete this manager?  This cannot be undone');

        if(!doDelete){
            return false;
        }
        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_AUTH_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_AUTH_COOKIE] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            setRdr("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_AUTH_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        var url =   '/managers/' +managerId;

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: 'delete',
            headers: {
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            

            setError({ msg: 'The manager has been deleted', style: "grn" });
            loadManagers()
            // flashActions.set({ msg: message, style: "grn" });
            // setRdr("/admin/manage/clients");

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error deleting the manager", style: "red" });
        });

    }

    const checkUserPermissions = () =>{
        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie[process.env.REACT_APP_AUTH_COOKIE] == "undefined" || authCookie[process.env.REACT_APP_AUTH_COOKIE] === "undefined" ) {
            // Not logged in so everything is cool
            setRdr("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie[process.env.REACT_APP_AUTH_COOKIE], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));
        
        if(!reformData.permissions.Clients){
            setRdr(redirectUrl)
            return
        } else {
            loadManagers();
        }
    }

    /* eslint-disable */
    useEffect(() => {
        document.title = 'Managers :: NeuroSphere';
        checkUserPermissions()
    },[])
    /* eslint-enable */

    // useEffect to load up the auth check when the component mounts
    useEffect(() => {
        if (flashState.msg) {
            setError(flashState);
            flashActions.clear();
        }
    }, [setError, flashState, flashActions]);

    return (
        <ManagerList
            error={ error }
            rdr={ rdr }
            managers={managers}
            editManagerMap={editManagerMap}
            toggleEdit={toggleEdit}
            handleEditUpdate={handleEditUpdate}
            savingRequest={savingRequest}
            saveManager={saveManager}
            deleteManager={deleteManager}
        />
    );
}

export default ManagerListContainer;
