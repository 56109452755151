// Core react and plugins
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import Demo from '../../components/demo/demo';

import Encryption from '../../utilities/encryption';
import CryptoJS from 'crypto-js';
import Fetching from '../../utilities/fetching';
import WhiteLabel from '../../utilities/whitelabel';


const DemoContainer = (props) => {

    // State management 
    let encSecret = process.env.REACT_APP_ENC_SECRET;
    const [websiteUrl, setWebsiteUrl] = useState("");
    const [displayWidget, setDisplayWidget] = useState(false);
    const [widgetTrigger , setWidgetTrigger] = useState(false);
    const [msgs, setMsgs] = useState([]);
    const [msg, setMsg] = useState("");
    const [error, setError] = useState(false);
    const [widgetError, setWidgetError] = useState(false);

    const [accCookie, setCookie, removeCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // Load up the stats for the header of the page 
    const startChat = (e) => {

        e.preventDefault();
        setError(false);

        // Check whether they have already tested the demo, based upon cookie
        const isLimitless = process.env.REACT_APP_UNLIMITED_DEMO;
        console.log(isLimitless);
        if (typeof isLimitless == "undefined" || parseInt(isLimitless) !== 1) {
            let existingTest = Encryption.extractTest(accCookie);
            if (existingTest && existingTest !== websiteUrl) {
                setError({ msg: "Sorry, but you can only test " + WhiteLabel.changeName("ChatBack AI") + " with one website before you create an account. If you'd like to test more websites, please create a free account.", style: "red" });
                return;
            }
        }

        // Run the fetch request
        fetch(process.env.REACT_APP_API_BASE + "/public/demo-chat", {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body: JSON.stringify({ url: websiteUrl })
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Display the widget for them to chat with
                setDisplayWidget(true);

                // TODO: Store a cookie to say they have already tested the demo
                var encData = { t: CryptoJS.AES.encrypt(JSON.stringify(websiteUrl), encSecret).toString() };
                setCookie(process.env.REACT_APP_AUTH_COOKIE, encData, { path: '/', secure: true, sameSite: true });

                // Display the widget after a pause
                setTimeout(() => {
                    setWidgetTrigger(true);
                }, 1500);
                return;

            } 

            // Display a returned error message
            setError({ msg: data.msg, style: "red" });

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error starting your test. Please try again.", style: "red" });
        });

    }

    // Handle a key press that could be a submission by enter key 
    const handlePossibleSubmit = (e) => {
        // Check for the enter key
        if (e.key === "Enter") {
            submitMsg();
        }
        setWidgetError(false);
    }

    // Handle the submission of a message 
    const submitMsg = (e) => {
        if (typeof e !== "undefined") e.preventDefault();

        // Check for a customer message 
        if (msg.trim() === "") {
            setWidgetError({ msg: "Please enter a message.", style: "red" });
            return;
        }
        setWidgetError(false);

        // Add the customer message to the messages array 
        let updatedMsgs = [...msgs, { msg: msg, type: "customer" }];
        let submission = { url: websiteUrl, msg: msg }
        setMsg("");
        scrollWidget();

        // Do the fetch to hanbdle the selecton of a response
        // Run the fetch request
        fetch(process.env.REACT_APP_API_BASE + "/public/demo-chat/msg", {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(submission)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Display the widget for them to chat with
                setDisplayWidget(true);

                // Update the chat widget with the new message
                var newMsgs = updatedMsgs;
                for (var i in data.detail) {
                    newMsgs.push(data.detail[i]);
                }
                setMsgs(newMsgs);
                scrollWidget();
                return;

            } 

            // Display a returned error message
            setWidgetError({ msg: data.msg, style: "red" });

        })
        .catch(function (error) {
            setWidgetError({ msg: "Sorry but there was an error processing your message. Please try again.", style: "red" });
        });

    }

    // Scroll the widget to the bottom
    const scrollWidget = () => {
        setTimeout(() => {
            const widget = document.querySelector(".widget-body");
            widget.scrollTop = widget.scrollHeight;
        }, 100);
    }

    return (
        <Demo 
            websiteUrl={ websiteUrl }
            setWebsiteUrl={setWebsiteUrl}
            displayWidget={ displayWidget }
            widgetTrigger={ widgetTrigger }
            msgs={ msgs }
            msg={ msg }
            setMsg={ setMsg }
            submitMsg={ submitMsg }
            handlePossibleSubmit={ handlePossibleSubmit }
            startChat={ startChat }
            widgetError={ widgetError }
            error={ error }
        />
    );
}

export default DemoContainer;