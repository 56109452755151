// Core react and plugins
import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import moment from 'moment';

import Loader from '../common/loader';
import { Link } from 'react-router-dom';
import WhiteLabel from '../../utilities/whitelabel';

const Chats = (props) => {

    return (
        <div>
            <Helmet>
                <title>Chat history :: {WhiteLabel.changeName("ChatBack AI")}</title>
            </Helmet>

            <h1>Chat history</h1>

            { props.loading && props.chats.length === 0 ? (
                <Loader /> 
            ) : (
                <Fragment>
                    <table>
                        <thead>
                            <tr>
                                <th className="med-hide">Chat reference</th>
                                <th className="sml-hide">Total messages</th>
                                <th>Created</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            { props.chats.length === 0 ? (
                                <tr>
                                    <td colSpan="4" className='centre padded'><em>No chats found</em></td>
                                </tr>
                            ) : (
                                <Fragment>
                                    {props.chats.map((chat, index) => (
                                        <tr key={index}>
                                            <td className="med-hide">{chat.ref}</td>
                                            <td className="sml-hide">{chat.totalMsgs}</td>
                                            <td>{ moment(chat.createdAt).format("HH:mm, DD/MM/YYYY") }</td>
                                            <td><Link to={ "/chats/" + chat.ref } className="btn primary sml">View</Link></td>
                                        </tr>
                                    ))}
                                </Fragment>
                            )}
                        </tbody>
                    </table>
                </Fragment>   
            )}
            
        </div>
    );
}

export default Chats;