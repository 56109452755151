// Core react and plugins
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

import Encryption from '../../utilities/encryption';
import Fetching from '../../utilities/fetching';


import Website from '../../components/gpt-contents/website';

const WebsiteContainer = (props) => {

    // State management 
    const [btnTxt, setBtnTxt] = useState("Save");
    const [error, setError] = useState(false);
    const [valErrors, setValErrors] = useState(false);
    const [url, setUrl] = useState("");
    const [addnUrls, setAddnUrls] = useState([""]);
    const [plan, setPlan] = useState("free");

    // Cookie / auth
    const [accCookie, setCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // Add an additional URL
    const addUrl = (e) => {
        e.preventDefault();
        setAddnUrls([...addnUrls, ""]);
    }

    // Remove a URL from the list
    const removeUrl = (e, i) => {
        e.preventDefault();
        let newUrls = [...addnUrls];
        newUrls.splice(i, 1);
        if (newUrls.length === 0) newUrls = [""];
        setAddnUrls(newUrls);
    }

    // Trakc the update of a field 
    const updateField = (field, value) => {
        switch (field) {
            case "url":
                setUrl(value);
                break;
            default:
                let newUrls = [...addnUrls];
                newUrls[field] = value;
                setAddnUrls(newUrls);
        }
    }

    // Load up existing data 
    const loadUrls = () => {

        // Get the auth data from the cookie
        let authInfo = Encryption.extractHeaders(accCookie);
        if (typeof authInfo.client.Package != "undefined") setPlan(authInfo.client.Package);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/config/urls", {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.token,
                'api-key': authInfo.key
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Store a cookie with the user reference
                setUrl(data.detail.url);
                if (data.detail.addnUrls === null) {
                    setAddnUrls([""]);
                } else {
                    setAddnUrls(data.detail.addnUrls);
                }
                return;

            } 

            // Display the validation errors and error message 
            setError({ msg: data.msg, style: "red" });

        })
        .catch(function (error) {
            console.log(error);
            setError({ msg: "Sorry but there was an error retrieving the data URLsvfor your widget. Please refresh the page to try again", style: "red" });
        });

    }

    // Handle the save of the URL data 
    const saveUrls = (e) => {
        e.preventDefault();
        setBtnTxt("Saving...");

        // Get the auth data from the cookie
        let authInfo = Encryption.extractHeaders(accCookie);

        // Assemble the save data
        let saveData = {
            url: url,
            addnUrls: addnUrls
        }

        // Run the save 
        fetch(process.env.REACT_APP_API_BASE + "/config/urls", {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.token,
                'api-key': authInfo.key
            },
            body: JSON.stringify(saveData)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setBtnTxt("Save");
            scrollToTop();

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Redirect the form to the next step
                setError({ msg: data.msg, style: "green" });
                setValErrors(false);
                return;

            } 

            // Display the validation errors and error message 
            setError({ msg: data.msg, style: "red" });
            // Process validation errors
            if (data.rslt === "invalid-data") {
                let valErrs = Fetching.processErrors(data.detail);
                setValErrors(valErrs);
            }

        })
        .catch(function (error) {
            scrollToTop();
            setBtnTxt("Save");
            setError({ msg: "Sorry but there was an error saving your data. Please check your entry and try again", style: "red" });
        });

    }

    // Function to trigger the page scrolling to the top
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    // On page load call the loadUrls function 
    /* eslint-disable */
    React.useEffect(() => {
        loadUrls();
    }, []);
    /* eslint-enable */

    return (
        <Website 
            btnTxt={btnTxt}
            error={error}
            valErrors={valErrors}
            url={url}
            addnUrls={addnUrls}
            addUrl={addUrl}
            removeUrl={removeUrl}
            updateField={updateField}
            saveUrls={saveUrls}
            plan={plan}
        />
    );
}

export default WebsiteContainer;