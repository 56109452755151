// Sign out a user + end the session
// Core react and plugins
import React, { useState, useEffect } from 'react';

// Utilities
import Fetching from '../../../utilities/fetching';

// State management
import useAuth from '../../../store/auth';
import { useCookies } from 'react-cookie';

// View components
import Logout from '../../../components/users/auth/logout';
import Encryption from '../../../utilities/encryption';

const LogoutContainer = () => {

    // State management
    const [rdr, setRdr] = useState(false);
    const [authState, authActions] = useAuth(); // eslint-disable-line
    const [authCookie, setCookie, removeCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // Logout (handled upon component mount)
    const logout = () => {

        // Extract the auth data from the cookie
        let authInfo = Encryption.extractHeaders(authCookie);

        // Submit the logout request
        fetch(process.env.REACT_APP_API_BASE + '/users/logout', {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Remove all API tokens etc
            // Clear the cookie
            removeCookie(process.env.REACT_APP_AUTH_COOKIE, { path: '/', secure: true, sameSite: true });

            // Clear the auth state
            authActions.clearAuth();

            // Return to the login screen
            setRdr("/");

        })
        .catch(function (error) {
            // Clear the cookie
            removeCookie('csirt', { path: '/', secure: true, sameSite: true });
            // Clear the auth state
            authActions.clearAuth();
            // Return to the login screen
            setRdr("/");
        });

    }

    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        logout();
    }, []);
    /* eslint-enable */

    return (
        <Logout
            rdr={ rdr }
        />
    );
}

export default LogoutContainer;
