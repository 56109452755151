// Core react and plugins
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import Packages from '../../components/billing/packages';
import Encryption from '../../utilities/encryption';
import Fetching from '../../utilities/fetching';

const PackagesContainer = (props) => {

    const [usage, setUsage] = useState(false);
    const [status, setStatus] = useState(false);
    const [currency, setCurrency] = useState("gbp");
    const [btnTxt, setBtnTxt] = useState({ personal: "Subscribe to Personal", pro: "Subscribe to Pro", premium: "Subscribe to Premium" });
    const [error, setError] = useState(false);
    const [authCookie, setCookie, removeCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // Upon load check whether this account is already subscribed to a plan
    // SO we can tweak the wording of the titles and buttons etc
    const checkPlan = (trackSuccess) => {

        // Get the auth info from the cookie
        let authInfo = Encryption.extractHeaders(authCookie);

        fetch(process.env.REACT_APP_API_BASE + '/billing', {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (typeof data.rslt != "undefined" && data.rslt === "success") {
                setStatus(data.detail);
                if (data.detail.currency !== "") setCurrency(data.detail.currency.toLowerCase());
                if (trackSuccess) {
                    triggerGaSuccess(data.detail.package);
                }
                return;
            }

        })
        .catch(function (error) {
            console.log('Request failed', error);
        });

    }

    // Get the usage stats for this account
    const getUsage = () => {

        // Get the auth info from the cookie
        let authInfo = Encryption.extractHeaders(authCookie);

        fetch(process.env.REACT_APP_API_BASE + '/billing/usage', {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (typeof data.rslt != "undefined" && data.rslt === "success") {

                // Set the usage data
                setUsage(data.detail);

            }

        })
        .catch(function (error) {
            console.log('Request failed', error);
        });

    }

    // Handle the press of the subscribe button for a package
    // Leads to the redirection to the Stripe Subscription checkout page
    const subscribeTo = (e, packageType) => {

        e.preventDefault();

        // Set the button text
        let prevBtn = btnTxt[packageType];
        var newBtnTxt = { ...btnTxt };
        newBtnTxt[packageType] = "Redirecting...";
        setBtnTxt(newBtnTxt);

        // Get the auth info from the cookie 
        let authInfo = Encryption.extractHeaders(authCookie);  

        fetch(process.env.REACT_APP_API_BASE + '/billing/subscribe/create-subscription/' + packageType + "/" + currency, {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (typeof data.rslt != "undefined" && data.rslt === "success") {
                window.location.href = data.detail;
                return;
            }

            var newBtns = { ...btnTxt };
            newBtns[packageType] = prevBtn;
            setBtnTxt(newBtns);

            // If this was an update rather than a new subscription display the result or error 
            if (typeof data.rslt != "undefined" && data.rslt === "update-success") {
                setError({ msg: data.msg, style: "grn" })
                checkPlan(true);
                return;
            }
            setError({ msg: data.msg, style: "red" })

        })
        .catch(function (error) {
            console.log('Request failed', error);
            var newBtns = { ...btnTxt };
            newBtns[packageType] = prevBtn;
            setBtnTxt(newBtns);
        });

    }

    // Track the success trigger 
    const triggerGaSuccess = (plan) => {

        if (process.env.REACT_APP_SITE_BASE === "https://app.chatback.ai") {
        
            var val = 19;
            switch (plan) {
                case "personal":
                    val = 19;
                    plan = "Standard";
                    break;
                case "pro":
                    val = 49;
                    plan = "Pro";
                    break;
                case "premium":
                    val = 99;
                    plan = "Premium";
                    break;
                default:
                    val = 19;
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'purchase',
                'value': val,
                'items': [plan]
            });
            console.log("Pushed GTM purchase event");

        }

    }

    // Handle the cancellation of a subscription
    const cancel = (e) => {

        if (!window.confirm("Are you sure you want to cancel your subscription?")) {
            return;
        }

        e.preventDefault();

        // Get the auth info from the cookie 
        let authInfo = Encryption.extractHeaders(authCookie);  

        fetch(process.env.REACT_APP_API_BASE + '/billing/subscribe', {
            method: 'delete',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (typeof data.rslt != "undefined" && data.rslt === "success") {
                setError({ msg: data.msg, style: "grn" })
                setStatus(false);
                return;
            }

            setError({ msg: data.msg, style: "red" });

        })
        .catch(function (error) {
            console.log('Request failed', error);
            setError({ msg: "Sorry but there was an error cancelling your subscription. Please try again.", style: "red" });
        });

    }

    /* eslint-disable */
    useEffect(() => {
        checkPlan();
        getUsage();
    }, []);
    /* eslint-enable */

    return (
        <Packages 
            subscribeTo={subscribeTo}
            btnTxt={btnTxt}
            isCancel={ props.isCancel }
            error={error}
            cancel={cancel}
            status={status}
            usage={usage}
            currency={currency}
            setCurrency={setCurrency}
        />
    );
}

export default PackagesContainer;