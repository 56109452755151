import React from 'react';
import WhiteLabel from '../../utilities/whitelabel';
import Colour from '../../utilities/contrast-check';

function TestLaunchBtn(props) {

    if (!props.config || props.config.colour === "") {
        return "";
    }

    let colClass = Colour.whichTxt(props.config.colour, "#2a2a2a", "#ffffff");

    return (
        <button className={WhiteLabel.changeClass("launch-btn " + colClass)} style={{ backgroundColor: props.config.colour }} onClick={ (e) => props.startChat(e) }>
            <p>{WhiteLabel.changeLaunch("Ask a question")}</p>
        </button>
    )
}

export default TestLaunchBtn;
