// Core react and plugins
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

import Password from '../../common/forms/password';

// Components
import Error from './../../common/forms/error';
import PwMeter from './pw-meter';
import SetupHeader from '../../common/setup-header';
import WhiteLabel from '../../../utilities/whitelabel';

// Component class itself
function SetPw(props) {

    return (
        <div className="registration">
            <Helmet>
                <title>Set password :: {WhiteLabel.changeName("ChatBack AI")}</title>
            </Helmet>

            <SetupHeader title="Set password" subTitle={false} />

            <div className="login-content">

                <Error error={ props.error } />

                <form>

                    <PwMeter { ...props } /> 
                    <Password label={'Password'} id={'password'} handleChange={ (e) => props.handleUpdate("password", e)} />
                    <Password label={'Confirm Password'} id={'confirm'} handleChange={ (e) => props.handleUpdate("confirm", e)} />

                    <button onClick={ (e) => props.submitFunc(e) } className="btn primary wide">{ props.btnTxt }</button>
                    <p className="sml right">Forgotten your password? <Link to="/u/forgotten-password">Reset it here</Link></p>
                </form>

            </div>
        </div>

    );

}

export default SetPw;