// Core react and plugins
import React, {useState, useEffect} from 'react';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';

import Encryption from '../../utilities/encryption';
import Fetching from '../../utilities/fetching';

import ChatView from '../../components/chats/chat-view';

const ChatViewContainer = (props) => {

    const [chat, setChat] = useState(false);
    const [loading, setLoading] = useState(true);

    const [accCookie, setCookie, removeCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // Get the chat ID from the URL using params
    const { chatRef } = useParams();

    // Load up the chat for the client
    const loadChat = () => {

        // Get the auth data from the cookie
        let authInfo = Encryption.extractHeaders(accCookie);

        // Run the fetch request
        fetch(process.env.REACT_APP_API_BASE + "/chats/view/" + chatRef, {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.token,
                'api-key': authInfo.key
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Process the lakes into the appropriate format
                setChat(data.detail);

            } 
            setLoading(false);

        })
        .catch(function (error) {});

    }

    /* eslint-disable */
    useEffect(() => {
        loadChat();
    }, []);
    /* eslint-enable */

    return (
        <ChatView 
            chat={chat}
            loading={loading}
        />
    );
}

export default ChatViewContainer;