// Core react and plugins
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useParams } from 'react-router-dom';

import Encryption from '../../utilities/encryption';
import Fetching from '../../utilities/fetching';
import PackageResults from '../../components/billing/package-results';

const PackageResultsContainer = (props) => {

    const [error, setError] = useState(false);

    const [authCookie, setCookie, removeCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    const { search } = useLocation();
    const urlParams = useParams();
    
    // Convert the search params into an object
    const formatSearchParams = (search) => {
        let params = {};
        search = search.replace("?", "");
        search.split("&").forEach((param) => {
            let [key, value] = param.split("=");
            params[key] = value;
        });
        return params;
    }

    // Check success 
    // If we have a successful subscription set up then:
    // - Save the details based upon session (and retrieve the subscription ID)
    // - Open up the success form
    const handleSuccess = () => {
        let qs = formatSearchParams(search);

        // If there is no session ID then return an error
        if (typeof qs.session_id === "undefined") {
            setError({ msg: "Sorry, but there was an issue confirming your subscription. Please get in touch with our team who can resolve it for you: support@chatback.ai.", style: "red" });
            return;
        }

        // Get the auth info from the cookie
        let authInfo = Encryption.extractHeaders(authCookie);

        // Store the session details
        fetch(process.env.REACT_APP_API_BASE + '/billing/subscribe/store-subscription/' + qs.session_id, {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
                
            if (typeof data.rslt != "undefined" && data.rslt === "success") {
                // Store the subscription ID to state
                if (data.detail === "Personal") data.detail = "Standard";
                if (data.detail !== "existing" && data.detail !== "") setError({ msg: "You have been successfully subscribed to the \"" + data.detail + "\" plan.", style: "green" });
                triggerGaSuccess(data.detail);
                return;
            }

            setError({ msg: "Sorry, but there was an issue confirming your subscription. Please get in touch with our team who can resolve it for you: support@chatback.ai.", style: "red" });
                
        })
        .catch(function (error) {
            setError({ msg: "Sorry, but there was an issue confirming your subscription. Please get in touch with our team who can resolve it for you: support@chatback.ai.", style: "red" });
            console.log('Request failed', error);
        });

    } 

    // Track the success trigger 
    const triggerGaSuccess = (plan) => {

        if (process.env.REACT_APP_SITE_BASE === "https://app.chatback.ai") {

            var val = 19;
            switch (plan) {
                case "Standard":
                    val = 19;
                    break;
                case "Pro":
                    val = 49;
                    break;
                case "Enterprise":
                    val = 99;
                    break;
                default:
                    val = 19;
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'purchase',
                'value': val,
                'items': [plan]
            });
            console.log("Pushed GTM purchase event");
        
        }

    }

    // Handle the outcomes 
    /* eslint-disable */
    useEffect(() => {
        if (urlParams.rslt === "success") {
            handleSuccess();
        } else {
            handleCancel();
        }
    }, []);
    /* eslint-enable */

    return (
        <PackageResults 
            error={error}
        />
    );
}

export default PackageResultsContainer;