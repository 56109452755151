// Core react and plugins
import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import moment from 'moment';

import Loader from '../../common/loader';
import WhiteLabel from '../../../utilities/whitelabel';

const Clients = (props) => {

    const packages = {
        "free": "Free",
        "personal": "Standard",
        "pro": "Professional",
        "premium": "Premium"
    }

    return (
        <div>
            <Helmet>
                <title>Client management :: {WhiteLabel.changeName("ChatBack AI")}</title>
            </Helmet>

            <h1>Client management</h1>

            { props.loading && props.clients.length === 0 ? (
                <Loader /> 
            ) : (
                <Fragment>
                    <table>
                        <thead>
                            <tr>
                                <th>First name</th>
                                <th>Last name</th>
                                <th className="sml-hide">Email address</th>
                                <th className="med-hide">Verified</th>
                                <th className="med-hide">Package</th>
                                <th className="med-hide">Widget messages</th>
                                <th className="med-hide">Test messages</th>
                                <th className="med-hide">URL(s)</th>
                                <th className="med-hide">Created</th>
                            </tr>
                        </thead>
                        <tbody>
                                {props.clients.length === 0 ? (
                                <tr>
                                    <td colSpan="4" className='centre padded'><em>No clients found</em></td>
                                </tr>
                            ) : (
                                <Fragment>
                                    {props.clients.map((client, index) => (
                                        <tr key={index}>
                                            <td>{client.usrFirst}</td>
                                            <td>{client.usrLast}</td>
                                            <td className="sml-hide">{client.usrEmail}</td>
                                            <td className="sml-hide">{ client.isVerified ? "Yes" : "" }</td>
                                            <td className="med-hide">{ packages[client.package]}</td>
                                            <td className="med-hide">{client.totalMsgs}</td>
                                            <td className="med-hide">{ client.internalMsgs }</td>
                                            <td className="med-hide">{ client.urls === null || client.urls.length === 0 ? (
                                                "-"
                                            ) : (
                                                <ul>
                                                    {client.urls.map((url, index) => (
                                                        <li key={index}>{url}</li>
                                                    ))}
                                                </ul>
                                            )}</td>
                                            <td className="med-hide">{moment(client.createdAt).format("DD/MM/YYYY") }</td>
                                        </tr>
                                    ))}
                                </Fragment>
                            )}
                        </tbody>
                    </table>
                </Fragment>   
            )}
            
        </div>
    );
}

export default Clients;