// Core react and plugins
import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";

import ValidationError from '../common/forms/validation-error';
import Error from '../common/forms/error';
import WhiteLabel from '../../utilities/whitelabel';
import TestContainer from '../../containers/demo/test-container';

const Website = (props) => {

    return (
        <div className="form-scrn">
            <Helmet>
                <title>Website content :: {WhiteLabel.changeName("ChatBack AI")}</title>
            </Helmet>

            <h1>Website content</h1>

            <form>

                <Error error={props.error} />

                <div className="input text">
                    <label htmlFor="primary">Primary website URL</label>
                    <input type="text" id="primary" placeholder="Enter URL..." value={ props.url } onChange={ (e) => props.updateField("url", e.target.value) } />
                </div>

                { props.plan === "premium" &&
                    <Fragment>
                        <h3>Additional website URLs (e.g. subdomains, microsites etc.)</h3>
                        <p>If you operate additional websites that you would like to use to answer customer queries, then you can add them below:</p>

                        <div className="addn">

                            { props.addnUrls.length === 0 ? (
                                <div className="input text">
                                    <label htmlFor="addnUrl0">Additional website URL 1</label>
                                    <input type="text" id="addnUrl0" placeholder="Enter URL..." />
                                    <ValidationError errors={props.valErrors} fieldName="AddnUrls0" />
                                </div>
                            ) : (
                                <Fragment>
                                    {props.addnUrls.map((url, i) => (
                                        <div className="input text" key={i}>
                                            <button className="remove-btn right" onClick={ (e) => props.removeUrl(e, i) }>Remove</button>
                                            <label htmlFor={`addnUrl${i}`}>Additional website URL {i + 1}</label>
                                            <input type="text" id={`addnUrl${i}`} value={ url } placeholder="Enter URL..." onChange={ (e) => props.updateField(i, e.target.value) } />
                                            <ValidationError errors={props.valErrors} fieldName={"AddnUrls" + i} />
                                        </div>
                                    ))}
                                </Fragment>
                            )}

                        </div>

                        <button className="addn-btn" onClick={ (e) => props.addUrl(e) }>Add URL</button>
                    </Fragment>
                }

                <button className='btn primary' onClick={ (e) => props.saveUrls(e) }>{ props.btnTxt }</button>

            </form>

            {parseInt(process.env.REACT_APP_INTERNAL_TEST) === 1 &&
                <div className="int-widget">
                    <TestContainer />
                </div>
            }
        </div>
    );
}

export default Website;