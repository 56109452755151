// Core react and plugins
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import Encryption from '../../../utilities/encryption';
import Fetching from '../../../utilities/fetching';

import Prompts from '../../../components/admin/prompts/prompts';

const PromptsContainer = (props) => {

    const [prompts, setPrompts] = useState({ txtPrompt: "", chatPrompt: "", sysPrompt: "" });
    const [btnTxt, setBtnTxt] = useState("Save prompts");
    const [error, setError] = useState(false);

    const [authCookie, setCookie, removeCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // Upon load check whether this account is already subscribed to a plan
    // SO we can tweak the wording of the titles and buttons etc
    const getPrompts = () => {

        // Get the auth info from the cookie
        let authInfo = Encryption.extractHeaders(authCookie);
        setError(false);

        fetch(process.env.REACT_APP_API_BASE + '/prompts', {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (typeof data.rslt != "undefined" && data.rslt === "success") {
                setPrompts(data.detail);
                return;
            }
            setError({ msg: data.msg, style: "red" })

        })
        .catch(function (error) {
            setError({ msg: "Sorry, there was an error retrieving the prompts. PLease refresh the page to try again.", style: "red" })
            console.log('Request failed', error);
        });

    }

    // Handle changes to the inputs
    const handleChange = (e) => {

        let newPrompts = { ...prompts };
        newPrompts[e.target.name] = e.target.value;
        setPrompts(newPrompts);
        console.log(newPrompts);

    }

    // Handle the press of the subscribe button for a package
    // Leads to the redirection to the Stripe Subscription checkout page
    const handleSubmit = (e) => {

        e.preventDefault();

        // Set the button text
        setBtnTxt("Saving...");

        // Get the auth info from the cookie 
        let authInfo = Encryption.extractHeaders(authCookie);

        fetch(process.env.REACT_APP_API_BASE + '/prompts', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body: JSON.stringify(prompts)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setBtnTxt("Save prompts");

            if (typeof data.rslt != "undefined" && data.rslt === "success") {
                setError({ msg: data.msg, style: "grn" })
                return;
            }
            setError({ msg: data.msg, style: "red" })

        })
        .catch(function (error) {
            console.log('Request failed', error);
            setError({ msg: "Sorry but there was an error saving the prompts. Please try again.", style: "red" })
            setBtnTxt("Save prompts");
        });

    }

    /* eslint-disable */
    useEffect(() => {
        getPrompts();
    }, []);
    /* eslint-enable */

    return (
        <Prompts 
            prompts={prompts}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            error={error}
            btnTxt={btnTxt}
        />
    );
}

export default PromptsContainer;