
// Check the status of a fetch request
const permissionsList = {
  'users': {
    'create': ['dev', 'admin'],
    'network-tab': ['dev', 'admin', 'stats-admin', 'user'],
    'network': ['user']
  },
  'prompts': {
    'manage': 'Clients'
  }
}

const Permissions = {
  // Check whether a user has permissions to access a specific action based upon their group
  actionCheck: function (resource, action, usrPerms) {

    // If nothing is set in the permissions list then assume it can be accessed 
    // This is the default state for actions
    if (typeof permissionsList[resource] == "undefined" || typeof permissionsList[resource][action] == "undefined") {
      return true;
    }

    // Get the entry from the permissions list 
    let perms = permissionsList[resource][action];


    if ( usrPerms[perms] ) {
      return true;
    }

    return false;
   
  }
}

export default Permissions;
