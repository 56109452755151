import React from 'react';

function DemoWidget(props){

    return (
        <div className={ props.widgetTrigger ? "sample-widget visible" : "sample-widget"}>
            <div className="widget-header" style={{ backgroundColor: props.col }}>
                <h5>Chat with us</h5>
            </div>
            <div className={ "widget-body"}>
                <div className="msg agpt-msg">
                    <p>{ props.intro !== "" ? props.intro : "Hi there! Thanks for visiting. How can I help you today?"}</p>
                </div>

                { props.msgs.map((msg, i) => 
                    <div className={ msg.type === "customer" ? "msg customer-msg" : "msg agpt-msg" } key={i} style={ msg.type === "customer" ? { backgroundColor: props.col } : {}}>
                        <p>{ msg.msg }</p>
                    </div>
                )}

                { props.widgetError && 
                    <div className={"widget-error " + props.widgetError.style}>
                        <p>{ props.widgetError.msg }</p>
                    </div>
                }
            </div>
            <div className='widget-sender'>
                <textarea placeholder="Enter your message..." value={ props.msg } onKeyUp={ (e) => props.handlePossibleSubmit(e) } onChange={ (e) => props.setMsg(e.target.value) }></textarea>
                <div className="send-btn" onClick={ (e) => props.submitMsg(e) }>
                    <div className="top" style={{ backgroundColor: props.col }}></div>
                    <div className="btm" style={{ backgroundColor: props.col }}></div>
                    Send
                </div>
            </div>
        </div>
    )
}

export default DemoWidget;
